<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Reservas desde el portal web
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Reserva" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <!--              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva reserva</button>-->
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Estado</th>
                <th>#</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Categoria</th>
                <th>Sucursal</th>
                <th>Salida - Regreso</th>
                <th>Pagado</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in items" :key="item.id"
                  class="text-center align-middle"
                  :class="item.revisado?'':'text-bold'">
                <td>
                  <span v-if="!item.revisado" class="bg-info text-white p-1 rounded-3">Nuevo</span>
                  <span v-else :class="getClassByPaymentStatus(item.estado)"
                        class="text-white p-1 rounded-3">{{ item.estado }}</span>
                </td>
                <td class="text-wrap" :class="item.estado==='Cancelado'?'text-danger':''">
                  {{ item.noReserva }}
                </td>
                <td class="text-wrap">{{ formatearFecha(item.fecha) }}</td>
                <td class="text-wrap">{{ item.nombre ? item.nombre : "" }} {{ item.apellidos }}</td>
                <td class="text-wrap">{{ item.categoria ? item.categoria.name : "" }}</td>
                <td class="text-wrap">{{ item.sucursal ? item.sucursal.name : "" }}</td>
                <td class="text-wrap">
                  {{ item.fechaRenta ? formatearFecha(item.fechaRenta) : "" }} -<br>
                  {{ item.fechaFin ? formatearFecha(item.fechaFin) : "" }}
                </td>
                <td class="text-wrap">
                  {{ item.estado === "No pagado" ? "Solo reserva" : "$ " + numeroFormateado(item.total) + " MXN" }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openDetalles(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Ver detalles de la reserva" data-container="body"
                            data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <div v-if="tienePermiso('cancelar_reservas')">
                      <button v-show="item.estado!=='Cancelado'" v-if="item.estado!=='Contratado'"
                              @click="Cancelar_Reserva(item.id)"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Cancelar la reserva" data-container="body"
                              data-animation="true"
                              class="btn btn-danger p-1 ms-1">
                        <i class="material-icons opacity-10">cancel</i></button>
                    </div>
                    <button @click="generateReport(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Generar reserva" data-container="body" data-animation="true"
                            class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
      <div class="row">
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <!--          Mostrando {{ items.length }} de-->
          <!--          {{ cant }}-->
        </div>
        <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
          <p class="me-3" style="margin-bottom: 0px">Elementos por página</p>
          <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
            <option :value=10>10</option>
            <option :value=50>50</option>
            <option :value=100>100</option>
          </select>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage"
                  :disabled="this.hasPreviousPage===false">
            <i class="material-icons opacity-10">skip_previous</i></button>
          <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="this.hasNextPage===false">
            <i class="material-icons opacity-10">skip_next</i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal DetallesReserva">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]"
         @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles de la reserva<i @click="closeDetalles"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">No. Reserva:</strong> &nbsp;
                {{ this.item.noReserva }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Nombre:</strong> &nbsp;
                {{ this.item.nombre }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Email:</strong> &nbsp;
                {{ this.item.email }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Teléfono:</strong> {{ this.item.telefono }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Renta:</strong><br>
                {{ formatearFecha(this.item.fechaRenta) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Días:</strong> &nbsp;
                {{ this.item.dias }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cantidad de vehículos:</strong> &nbsp;
                {{ this.item.cantidad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cobertura {{ item.tipoCobertura }}:</strong> &nbsp;
                $ {{ numeroFormateado(this.item.tarifaDiaria) }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de renta: </strong>$ {{ numeroFormateado(this.total_renta) }}
                MXN
              </li>

            </ul>
          </div>
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Procedencia:</strong> &nbsp;
                {{ this.item.origen === "portal" ? "Portal web" : this.item.origen }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Apellidos:</strong> &nbsp;
                {{ this.item.apellidos }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Edad:</strong> &nbsp; {{ this.item.edad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.item.sucursal.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Regreso:</strong><br>
                {{ formatearFecha(this.item.fechaFin) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.item.categoria.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Forma de
                  pago: </strong>{{ this.item.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de servicios extras: </strong>$
                {{ numeroFormateado(parseFloat(this.item.serviciosExtras) * parseInt(item.cantidad)) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total (Impuestos incluidos): </strong>$ {{ numeroFormateado(this.monto_total)
                }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total pagado: </strong>$
                {{ item.estado === "No pagado" ? "0.00" : numeroFormateado(this.item.total)
                }} MXN
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Comentarios: </strong>{{ this.item.comentarios ? this.item.comentarios : "" }}
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Servicios extras: </strong>
                <ul>
                  <li v-for="item in services_ex" :key="item.id">{{ item.servicio.name }}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="closeDetalles" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Seguridad">
    <div v-if="seguridad" :class="['modal', { 'show': seguridad }]" @transitionend="onTransitionEnd">
      <div class="modal-content1">
        <div class="row mb-3 border-bottom border-dark">
          <h6 class="text-start"><i class="fa fa-lock me-2"></i>Escriba su contraseña<i @click="closeSeguridad"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h6>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <input v-model="password" type="password" autocomplete="new-password"
                   class="form-control p-2 border border-secondary">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir su contraseña</p></div>
            </div>
            <div v-show="error_seguridad" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Contraseña incorrecta</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeSeguridad" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Comprobar" class="btn btn-dark" type="button">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="exportar_pdf" :class="['modal', { 'show': exportar_pdf }]" @transitionend="onTransitionEnd">
    <div class="modal-content-contrato">
      <div class="container contrato" ref="contrato_plantilla" id="contrato_open_pdf">
        <div class="row mb-3">
          <div class="col-3">
            <img src="@/assets/img/issyrentallogo.png"
                 style="width: 150px; height: 75px">
          </div>
          <div class="col-7 text-start">
            <p><span class="fw-bold">Dirección:</span> Blvd. Cuauhtemoc Norte 303, Col. Aereopuerto Tijuana, Baja
              California, C.P. 22404</p>
            <p><span class="fw-bold">Correo electrónico:</span> info@issyrental.com</p>
            <p><span class="fw-bold">Teléfono:</span> (664) 361 7721</p>
          </div>
          <div class="col-2 text-center">
            <p>Fecha: {{ formatearFecha(new Date()) }}</p>
            <p>Folio: {{ transaccion.noReserva }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 linea">
            <h4>Confirmación de reserva</h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <p>Recibí de:</p>
            <p>{{ transaccion.nombre }} {{ transaccion.apellidos }}</p>
          </div>
          <div class="col-4">
            <p>Teléfono:</p>
            <p>{{ transaccion.telefono }}</p>
          </div>
          <div class="col-4">
            <p>Correo electrónico:</p>
            <p>{{ transaccion.email }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <p>Fechas contratadas de:</p>
            <p>{{ formatearFecha(transaccion.fechaRenta) }}</p>
          </div>
          <div class="col-4">
            <p>Hasta:</p>
            <p>{{ SoloFecha(transaccion.fechaFin) }}</p>
          </div>
          <div class="col-4">
            <p>Hora de entrega:</p>
            <p>{{ SoloHora(transaccion.fechaFin) }}</p>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
            <p>Categoría de auto:</p>
            <p>{{ transaccion.categoria.name }}</p>
          </div>
          <div class="col-4">
            <p>Tarifa por día:</p>
            <p>$ {{ transaccion.tarifaDiaria }} MXN</p>
          </div>
          <div class="col-4">
            <p>Días contratados:</p>
            <p>{{ transaccion.dias }} Días</p>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
            <p>Tarifas y cargos</p>
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-between">
              <p>Renta de auto: </p>
              <p>$ {{ numeroFormateado(parseFloat(transaccion.tarifaDiaria) * parseInt(transaccion.dias)) }} MXN</p>
            </div>
            <div v-for="item in servicios" class="d-flex justify-content-between">
              <p>{{ item.servicio.name }}:</p>
              <p>$ {{ numeroFormateado(item.servicio.precio) }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>Total de extras:</p>
              <p>$ {{ numeroFormateado(transaccion.serviciosExtras) }} MXN</p>
            </div>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cargos totales: </p>
              <p>$ {{
                numeroFormateado(parseFloat(parseFloat(transaccion.tarifaDiaria) * parseInt(transaccion.dias) + parseFloat(transaccion.serviciosExtras)))
                }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cantidad abonada:</p>
              <p>$ {{ numeroFormateado(transaccion.total) }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cantidad por pagar:</p>
              <p>$
                {{
                numeroFormateado(parseFloat(monto_total) - parseFloat(transaccion.total))
                }}
                MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Forma de pago:</p>
              <p>{{ transaccion.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}</p>
            </div>
          </div>
          <div class="col-12">
            <h6 class="mb-2">Notas adicionales</h6>
            <span style="text-transform: uppercase">{{ transaccion.comentarios }}</span>

          </div>
        </div>
        <div class="row mb-5">
          <div class="col-8">
            <p class="fw-bold mb-3">Tarifas incluyen</p>
            <ul class="mb-2">
              <li v-for="element in coberturas" :key="element.id" v-show="element.isActive">{{ element.cobertura }}</li>
            </ul>
            <p>Penalización por cancelación fecha: 40% del pago</p>
            <p>Penalización por cancelación hasta 2 días antes 100% del pago</p>
          </div>
          <div class="col-4">
            <p>Salida en oficina</p>
            <p class="mb-3">{{ transaccion.sucursal.address }}</p>
            <p class="fw-bold mb-2">Requisitos para contratar</p>
            <p>Tarjeta de crédito bancaria</p>
            <p>Ser mayor de 25 años</p>
            <p>ID oficial vigente</p>
            <p>Licencia de conducir vigente</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2">
            <div class="linea"></div>
            <p>Juan Carlos Rendón</p>
            <p>Issy Rental</p>
          </div>
          <div class="col-6">

          </div>
          <div class="col-2">
            <div class="linea"></div>
            <p>{{ transaccion.nombre }} {{ transaccion.apellidos }}</p>
            <p>Cliente</p>
          </div>
          <div class="col-2">
          </div>
        </div>
        <div class="row">
          <h6>Términos y condiciones en <a href="https://www.issyrental.com/#/Terminos">https://www.issyrental.com/#/Terminos</a>
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click="generateReportPDF" class="btn btn-dark"><i class="fa fa-download me-2"></i> Descargar</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
import { mapGetters, mapState } from "vuex";
import html2pdf from "html2pdf.js";
import {
  CoberturasPerTarifaTotal, CoberturasPerTarifaTradicional,
  getReservations,
  getReservationsById,
  getReservationsNext,
  getReservationsPrev
} from "@/actions";
import { decodeBase64 } from "@/util";

const REVISADA = gql`
mutation Revisada($id: Int!) {
    revisada(id: $id) {
        success
        errors
    }
}`;
const CANCELAR = gql`mutation CancelarReserva($id:Int!, $agente:ID!) {
    cancelarReserva(id: $id, agente:$agente) {
        success
        error
    }
}`;
const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        isActive
    }
}`;
const ALLCLIENTE = gql`query AllCliente {
    allCliente {
         id
        nombre
        apellidos
        telefono
        email
        edad
        isActive
        direccion
        postal
        telefonoEmergencia
        ciudad
        estado
        pais
        tipo {
            id
            name
            isActive
        }
        licencia {
            id
            numero
            vencimiento
            estado
            permanente
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const SEGURIDAD = gql`
mutation Login($email:String! $password:String!) {
    login(email: $email password: $password) {
        success
        token
        refreshToken
        error
    }
}`;
const SERVICIOS_BY_RESERVE = gql`
query ServicesByReservation($id:ID!) {
    servicesByReservation(id: $id) {
        id
        servicio {
            name
        }
    }
}`;
export default {
  name: "List_Ciudad",
  components: { MaterialButton, VueGoogleAutocomplete, VueDatePicker },
  data() {
    return {
      coberturas: [],
      exportar_pdf: false,
      transaccion: null,
      misearch: "",
      services_ex: [],
      item: null,
      allTransacciones: [],
      allCategorias: [],
      allCliente: [],
      allSucursales: [],
      autocompleteItems: [],
      showSubmenu: false,
      showModal: false,
      showDetalles: false,
      error: false,
      total: 0,
      subtotal: 0,
      apellidos: "",
      categoria: null,
      email: "",
      nombre: "",
      sucursal: 0,
      telefono: "",
      comentarios: "",
      dias: 0,
      edad: 0,
      fechaFin: null,
      fechaRenta: null,
      password: "",
      seguridad: false,
      total_renta: 0,
      monto_total: 0,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: []
    };
  },
  apollo: {
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCategorias: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    },
    allCliente: {
      query: ALLCLIENTE,
      fetchPolicy: "cache-and-network"
    }
  },
  computed: {
    ...mapState(["permisos"]),
    ...mapGetters(["tienePermiso"])
  },
  created() {
    if (this.$store.state.reserva_notification) {
      this.openDetalles(this.$store.state.reserva);
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
    }
    this.loadDatas(true);
    this.$store.state.Cargando = true;
  },
  watch: {
    fechaRenta(value) {
      if (this.Difechas(Date.now(), value) <= 0) {
        this.fechaRenta = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de renta no puede ser anterior a la fecha de hoy"
        });
      }
    },
    fechaFin(value) {
      if (this.Difechas(this.fechaRenta, value) <= 0) {
        this.fechaRenta = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de renta"
        });
        return false;
      }
      this.dias = parseInt(this.Difechas(this.fechaRenta, value) / 24 < 1 ? 1 : this.Difechas(this.fechaRenta, value) / 24);
      if (this.categoria !== null && this.dias !== 0) {
        this.subtotal = this.categoria.tarifa * this.dias;
        this.total = this.subtotal;
      }
    },
    categoria(value) {
      if (this.dias !== 0 && value !== null) {
        this.subtotal = value.tarifa * this.dias;
        this.total = this.subtotal;
      }
    },
    allTransacciones(value) {
      this.$store.state.Cargando = false;
    },

    items() {
      this.$store.state.Cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
    misearch() {
      this.loadDatas(true);
    }
  },
  methods: {
    isBase64(str) {
      return /[a-zA-Z]/.test(str);
    },
    DecodeId(id) {
      if (this.isBase64(id)) {
        return decodeURIComponent(escape(window.atob(id))).split(":")[1];
      } else {
        return id;
      }

    },
    Cancelar_Reserva(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, cancelar la reserva!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: CANCELAR,
            variables: {
              id: this.DecodeId(id),
              agente: this.$store.state.userid
            }
          });
          if (data.cancelarReserva.success) {
            this.$apollo.mutate({
              mutation: REVISADA,
              variables: {
                id: this.DecodeId(id)
              }
            }).then(resp => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Reserva cancelada"
              });
              this.loadDatas(true);
            });
          }
        }
      });
    },
    Total(valor) {
      return parseFloat(valor);
    },
    Monto_total(extras, tarifa, dias) {
      return parseFloat(extras) + (parseFloat(tarifa) * parseInt(dias));
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    SoloFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY");
    },
    SoloHora(fecha) {
      moment.locale("es");
      return moment(fecha).format("h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    openDetalles(item) {
      this.item = item;
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
      this.monto_total = parseFloat(parseFloat(item.serviciosExtras) * parseInt(item.cantidad)) + (parseFloat(item.tarifaDiaria) * parseInt(item.dias) * parseInt(item.cantidad));
      this.total_renta = parseFloat(item.tarifaDiaria) * parseInt(item.dias) * parseInt(item.cantidad);
      this.$apollo.query({
        query: SERVICIOS_BY_RESERVE,
        variables: {
          id: this.DecodeId(item.id)
        }
      }).then(response => {
        this.services_ex = response.data.servicesByReservation;
      });
      this.showDetalles = true;
      document.body.classList.add("modal-open");
      this.$apollo.mutate({
        mutation: REVISADA,
        variables: {
          id: this.DecodeId(item.id)
        }
      }).then(resp => {
        this.loadDatas(true);
      });
    },
    generateReport(item) {
      getReservationsById(decodeBase64(item.id)).then(resp => {
        this.transaccion = resp.data.data.reservaById;
        console.log(this.transaccion);
        this.exportar_pdf = true;
        this.$store.state.Cargando = true;
        this.monto_total = parseFloat(parseFloat(this.transaccion.serviciosExtras) * parseInt(this.transaccion.cantidad)) + (parseFloat(this.transaccion.tarifaDiaria) * parseInt(this.transaccion.dias) * parseInt(this.transaccion.cantidad));
        if (this.transaccion.tipoCobertura === "total") {
          CoberturasPerTarifaTotal().then(response => {
            this.coberturas = response.data.data.coberturasPerTarifaTotal;
            setTimeout(() => {
              const contentToExport = this.$refs.contrato_plantilla;
              const options = {
                margin: [5, 5, 5, 5],
                filename: "Reserva.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "letter", orientation: "portrait" }
              };
              html2pdf()
                .from(contentToExport)
                .set(options)
                .output("blob", "reserva.pdf")
                .then(dataUri => {
                  const blob = new Blob([dataUri], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);
                  window.open(url, "_blank");
                });
              this.closeExportarPDF();
            }, 50);
          });
        } else {
          CoberturasPerTarifaTradicional().then(response => {
            this.coberturas = response.data.coberturasPerTarifaTradicional;
            setTimeout(() => {
              const contentToExport = this.$refs.contrato_plantilla;
              const options = {
                margin: [5, 5, 5, 5],
                filename: "Reserva.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "letter", orientation: "portrait" }
              };
              html2pdf()
                .from(contentToExport)
                .set(options)
                .output("blob", "reserva.pdf")
                .then(dataUri => {
                  const blob = new Blob([dataUri], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);
                  window.open(url, "_blank");
                });
              this.closeExportarPDF();
            }, 50);
          });
        }
      });
    },
    closeExportarPDF() {
      this.$store.state.Cargando = false;
      this.exportar_pdf = false;
      this.transaccion = null;
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.total = 0;
      this.subtotal = 0;
      this.apellidos = "";
      this.categoria = 0;
      this.email = "";
      this.nombre = "";
      this.sucursal = 0;
      this.telefono = "";
      this.comentarios = "";
      this.dias = 0;
      this.edad = 0;
      this.fechaFin = null;
      this.fechaRenta = null;
      this.showModal = null;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeDetalles() {
      this.item = null;
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
      this.showDetalles = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    Contrato(item) {
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
      this.$store.state.anterior = "Rentas_Portal";
      this.$store.state.reserva = item;
      this.$router.push("/contratos/nuevo");
    },
    autocomplete() {
      this.autocompleteItems = this.allCliente.filter(item =>
        item.nombre.toLowerCase().includes(this.nombre.toLowerCase())
      );
      this.showSubmenu = this.nombre !== "";
    },
    selectItem(item) {
      this.nombre = item.nombre;
      this.apellidos = item.apellidos;
      this.edad = item.edad;
      this.email = item.email;
      this.telefono = item.telefono;
      this.showSubmenu = false;
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Origen(item) {
      if (item === "portal") {
        return "Portal Web";
      } else if (item === "admin") {
        return "Administración";
      } else {
        return "Portal Web";
      }
    },
    openSeguridad(item) {
      this.seguridad = true;
      this.item = item;
      document.body.classList.add("modal-open");
    },
    Comprobar() {
      if (this.password === "") {
        this.error = true;
      } else {
        this.$apollo.mutate({
          mutation: SEGURIDAD,
          variables: {
            email: this.$store.state.email,
            password: this.password
          }
        }).then(response => {
          if (response.data.login.success) {
            this.closeSeguridad();
            this.Contrato();
          } else {
            this.error_seguridad = true;
          }
        });
      }
    },
    getClassByPaymentStatus(estado) {
      if (estado === "No pagado") {
        return "bg-danger";
      } else if (estado === "Pago total") {
        return "bg-success";
      } else if (estado === "Pago parcial") {
        return "bg-warning";
      } else {
        return "";
      }
    },

    loadDatas(init = false) {
      this.$store.state.Cargando = true;
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      getReservations({
        name: this.misearch,
        before: before,
        after: next,
        first: this.cant,
        origen:"portal"
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchReservations.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchReservations.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
            return value.node;
        });
      });
    },
    nextPage() {
      this.$store.state.Cargando = true;
      getReservationsNext({
        name: this.misearch,
        after: this.endCursor,
        first: this.cant,
        origen:"portal"
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchReservations.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
            return value.node;
        });
      }).catch((err) => {
        alert(err);
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    },
    prevPage() {
      this.$store.state.Cargando = true;
      getReservationsPrev({
        name: this.misearch,
        before: this.startCursor,
        first: this.cant,
        origen:"portal"
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchReservations.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
            return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}

.linea {
  padding-bottom: 10px;
  padding-top: 5px;
  border-bottom: 2px solid #000;
}

.text-adicional {
  font-style: italic;
  line-height: 18px;
  text-transform: uppercase;
  font-size: 16px;
}

div p {
  font-size: 14px;
  line-height: 16px;
  color: #000;
  font-weight: bold;
}

div li {
  font-size: 14px;
  line-height: 16px;
  color: #000;
  font-weight: bold;
}

.modal-content-contrato {
  background-color: white;
  padding: 20px;
  margin-top: 999999px;
  border-radius: 15px;
  width: 90%;
}
</style>
