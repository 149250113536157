<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Modelos
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar modelo" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Modelo</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Modelo</th>
                <th>Marca</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="modelo in modeloPerName" :key="modelo.id">
                <td class="text-sm font-weight-normal" :class="modelo.isActive?'':'text-danger'">
                  <div class="ms-3">{{ modelo.name }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="modelo.isActive?'':'text-danger'">
                  <div class="ms-3">{{ modelo.marca?modelo.marca.name:"Sin Marca" }}</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="modelo.isActive" @click="DesactiveModelo(modelo.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Modelo" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveModelo(modelo.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Modelo" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(modelo.name, modelo.marca?modelo.marca.id:1, modelo.id)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar Modelo" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="DeleteModelo(modelo.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Modelo" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Modelo<i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i></h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark mb-3 p-2" placeholder="Escriba el nombre del Modelo" type="text">
            <label class="form-label">Marca</label>
            <select v-model="marca" class="form-select border-bottom p-2">
              <option selected disabled value="0">Seleccione una Marca</option>
              <option v-for="marca in allMarcas" :value="marca.id" v-show="marca.isActive">{{ marca.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
          <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
            <p class="text-dark">Debe llenar todos los campos</p></div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addModelo" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Modelo<i @click="closeUpdate" class="material-icons-round opacity-10 modal-icon">close</i></h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control border-bottom border-dark p-2 mb-3" type="text">
            <label class="form-label">Modelo</label>
            <select v-model="marca" class="form-select border-bottom p-2">
              <option selected disabled value="0">Seleccione una Marca</option>
              <option v-for="marca in allMarcas" :value="marca.id" v-show="marca.isActive">{{ marca.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
        <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
          <p class="text-dark">Debe llenar todos los campos</p></div>
      </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateModelo" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const ALL_MARCAS=gql`
query AllMarcas {
    allMarcas {
        id
        name
        isActive
    }
}
`;
const BUSCAR_MODELO = gql`
          query ModeloPerName($name: String!) {
    modeloPerName(name: $name) {
        id
        name
        isActive
        marca {
            id
            name
            isActive
        }
    }
}
          `;
const DESACTIVE_MODELO = gql`
mutation($id:ID!){
  desactiveModelo(id: $id) {
        success
    }
}
`;
const ACTIVE_MODELO = gql`
mutation ActiveSucursal($id:ID!) {
    activeModelo(id: $id) {
        success
    }
}
`;
const INSERT_MODELO = gql`
mutation CreateModelo($id:ID!, $name:String!) {
    createModelo(idMarca: $id, name: $name) {
        success
    }
}
  `;
const UPDATE_MODELO = gql`
  mutation UpdateModelo($id:ID!, $name:String!, $id_marca:ID!) {
    updateModelo(idMarca: $id_marca, idModelo: $id, name: $name) {
        success
    }
}
  `;
const DELETE_MODELO = gql`
mutation DeleteModelo($id:ID!) {
    deleteModelo(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Sucursal",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      modeloPerName: [],
      allMarcas:[],
      showModal: false,
      UpdateModal:false,
      marca: 0,
      name: "",
      modeloid: 0,
      error: false
    };
  },
  apollo: {
    modeloPerName: {
      query: BUSCAR_MODELO,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allMarcas:{
      query:ALL_MARCAS,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addModelo() {
      if (this.marca === 0 || this.name === "") {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT_MODELO,
        variables: {
          name: this.name,
          id: parseInt(this.marca)
        },
        refetchQueries: [{ query: BUSCAR_MODELO, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Modelo creado"
        });
        this.closeModal()
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear la Sucursal"
        });
        this.closeModal()
        console.error("La mutación falló:", error);
      });
    },
    async updateModelo() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_MODELO,
          variables: {
            name: this.name,
            id_marca:this.marca,
            id: this.modeloid
          },
          refetchQueries: [{ query: BUSCAR_MODELO, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          this.closeUpdate()
          Toast.fire({
            icon: "success",
            title: "Modelo actualizado"
          });
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el Modelo"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteModelo(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_MODELO,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_MODELO, variables: { name: this.misearch } }]
          });
          if (data.deleteModelo.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Modelo eliminado"
            });
          }
        }
      });
    },
    async DesactiveModelo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE_MODELO,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_MODELO, variables: { name: this.misearch } }]
      });
      if (data.desactiveModelo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Modelo desactivado"
        });
      }
    },
    async ActiveModelo(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE_MODELO,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR_MODELO, variables: { name: this.misearch } }]
      });
      if (data.activeModelo.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Modelo activado"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, marca, id) {
      this.name = name;
      this.marca = marca;
      this.modeloid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.marca="";
      this.name="";
      this.modeloid=0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
