<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Ciudades
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
            <div class="input-group">
              <input v-model="misearch"  type="text" class="form-control input-icon" placeholder="Buscar Ciudad" aria-label="Recipient's username" aria-describedby="button-addon2">
            </div></div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva Ciudad</button></div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Ciudad</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="city in ciudadPerName" :key="city.id" class="text-center align-middle">
                <td :class="city.isActive?'':'text-danger'">{{city.name}}</td>
                <td :class="city.isActive?'':'text-danger'">{{city.estado?city.estado.name:""}}</td>
                <td><div class="btn-group pt-3" role="group">
                  <button v-if="city.isActive" @click="desactiveCity(city.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Desactivar Ciudad" data-container="body" data-animation="true"  class="btn btn-success p-1 ms-1">
                    <i class="material-icons opacity-10">block</i></button>
                  <button v-else @click="activeCity(city.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Activar Ciudad" data-container="body" data-animation="true"  class="btn btn-warning p-1 ms-1">
                    <i class="material-icons opacity-10">check_circle</i></button>
                  <button @click="openUpdate(city.name, city.id, city.estado?city.estado.id:0)" data-bs-toggle="tooltip" data-bs-placement="top" title="Actualizar Ciudad" data-container="body" data-animation="true" class="btn btn-info p-1 ms-1">
                    <i class="material-icons opacity-10">edit</i></button>
                  <button @click="DeleteCity(city.id)" data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Ciudad" data-container="body" data-animation="true" class="btn btn-danger p-1 ms-1">
                    <i class="material-icons opacity-10">delete</i></button>
                </div>
               </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Ciudad<i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i></h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Estado</label>
            <select class="form-select mb-3" v-model="estado">
              <option v-for="item in allEstados" :key="item.id" :value="item.id">{{item.name}}</option>
            </select>
          <label class="form-label">Ciudad</label>
            <input type="text" class="form-control border-bottom border-dark" v-model="address">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i> <p class="text-dark">Debe escribir una Ciudad</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button></div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addCity" class="btn btn-dark" type="button">Guardar</button></div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Ciudad<i @click="closeUpdate" class="material-icons-round opacity-10 modal-icon">close</i></h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Estado</label>
            <select class="form-select mb-3" v-model="estado">
              <option v-for="item in allEstados" :key="item.id" :value="item.id">{{item.name}}</option>
            </select>
            <label class="form-label">Ciudad</label>
            <input type="text" class="form-control border-bottom border-dark" v-model="address">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir una Ciudad</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="updateCity" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const DESACTIVE_CITY=gql`
mutation($id:ID!){
  desactiveCity(id: $id) {
        success
    }
}
`;
const BUSCAR_CITY=gql`
          query CiudadPerName($name:String!) {
              ciudadPerName(name: $name) {
                  id
                  name
                  estado{
                  id
                  name
                  }
                  isActive
              }
          }
          `;
const ACTIVE_CITY=gql`
mutation ActiveCity($id:ID!) {
    activeCity(id: $id) {
        success
    }
}
`;
const INSERT_CITY=gql`
  mutation AddCity($name:String!, $estado:ID!) {
    addCity(name: $name, estado:$estado) {
        success
        errors
    }
}
  `;
const UPDATE_CITY=gql`
  mutation UpdateCity($id:ID!, $name:String!, $estado:ID!) {
    updateCity(id: $id, name: $name, estado:$estado) {
        success
        errors
    }
}
  `;
const DELETE_CITY=gql`
mutation DeleteCity($id:ID!) {
    deleteCity(id: $id) {
        success
    }
}
`;
const ESTADOS = gql`
          {
            allEstados{
              id
              name
              abreviatura
              isActive
            }
          }
          `;
export default {
  name: "List_Ciudad",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: '',
      address: "",
      ciudadid:0,
      estado:0,
      ciudadPerName: [],
      showModal:false,
      UpdateModal:false,
      error:false,
      allEstados:[]
    }
  },
  apollo: {
    ciudadPerName: {
        query: BUSCAR_CITY,
        variables(){
          return {
            name: this.misearch
          }
        },
        fetchPolicy: 'cache-and-network',
      },
    allEstados: {
      query: ESTADOS,
      fetchPolicy: 'cache-and-network',
      }
  },
  methods: {
    async addCity(){
      if (this.address==="")
      {
        this.error=true
        return false
      }
             this.$apollo.mutate({
                  mutation: INSERT_CITY,
                  variables: {
                    name: this.address,
                    estado:this.estado
                  },
                  refetchQueries: [{ query: BUSCAR_CITY, variables:{ name: this.misearch} }]
                }).then(response => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                Toast.fire({
                  icon: 'success',
                  title: 'Ciudad creada'
                })
               this.closeModal()
                }).catch(error => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })

                Toast.fire({
                  icon: 'error',
                  title: 'Error al crear la ciudad'
                })
                  console.error('La mutación falló:', error)
                })
    },
    async updateCity(){
          await this.$apollo
              .mutate({
                mutation: UPDATE_CITY,
                variables: {
                  name: this.address,
                  id: this.ciudadid,
                  estado:this.estado
                },
                refetchQueries: [{ query: BUSCAR_CITY, variables:{ name: this.misearch} }]
              }).then(response => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: 'Ciudad actualizada'
              })
              this.closeUpdate()
            }).catch(error => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'error',
                title: 'Error al actualizar la Ciudad'
              })
              console.error('La mutación falló:', error)
            })
          },
    async DeleteCity(id){
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showDenyButton:true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton:false,
        cancelButtonText:"Cancelar",
        denyButtonText: 'Si, eliminar!',
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_CITY,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR_CITY, variables:{ name: this.misearch} }]
          })
          if (data.deleteCity.success){
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            Toast.fire({
              icon: 'error',
              title: 'Ciudad eliminada'
            })
          }
        }
      })
    },
    async desactiveCity(id){
      const {data}=await this.$apollo.mutate({
        mutation:DESACTIVE_CITY,
        variables:{
          id:id
        },
        refetchQueries: [{ query: BUSCAR_CITY, variables:{ name: this.misearch}}]
      })
      if (data.desactiveCity.success){
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'warning',
          title: 'Ciudad desactivada'
        })
      }
    },
    async activeCity(id){
      const {data}=await this.$apollo.mutate({
        mutation:ACTIVE_CITY,
        variables:{
          id:id
        },
        refetchQueries: [{ query: BUSCAR_CITY, variables:{ name: this.misearch}}]
      })
      if (data.activeCity.success){
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'Ciudad activada'
        })
      }
    },
    openUpdate(name, id, estado) {
      this.address = name;
      this.ciudadid = id;
      this.estado=estado;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.address = "";
      this.estado=0;
      this.ciudadid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    openModal() {
      this.address = "";
      this.estado=0;
        this.showModal = true;
        this.error=false
        document.body.classList.add('modal-open');
      },
    closeModal() {
      this.address = "";
      this.estado=0;
        this.showModal = false;
        this.error=false;
        document.body.classList.remove('modal-open');
      },
    onTransitionEnd(event) {
      if (event.propertyName === 'opacity' && !this.showModal) {
        this.$emit('close');
      }
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
    },
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}
.modal {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.modal.show{
  opacity: 1;
}

</style>
