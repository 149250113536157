<template>
  <div>
    <div class="row ms-2 mb-3">
      <div class="col-4 p-3">
        <VueDatePicker v-model="date" range auto-apply locale="es"
                       :multi-calendars="{ solo: true }" format="dd/MM/yyyy">
        </VueDatePicker>
      </div>
      <div class="col-4"></div>
      <div class="col-4 p-3">
        <select v-model="canal"
                :disabled="deshabilitar_canal"
                :class="[{'form-control':deshabilitar_canal},{'dropdown':!deshabilitar_canal},{'is-invalido':error_canal}]"
                class="form-control mb-3">
          <option value="todo">Todos</option>
          <option value="admin">Administración</option>
          <option value="portal">Portal web</option>
        </select>
      </div>
    </div>
    <table class="" id="miTabla" style="table-layout: fixed; width: 100%">
      <thead>
      <tr class="text-center">
        <th class="text-sm">Sucursal</th>
        <th class="text-sm">Ventas</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center align-middle" v-if="sin_registros">
        <td></td>
        <td>No existen registros</td>
        <td></td>
      </tr>
      <tr v-else class="text-center align-middle" v-for="item in sucursalesByDinero" :key="item.id"
      >
        <td class="text-sm" style="word-break: break-word;">{{ item.name }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          $ {{ numeroFormateado(ventas[item.id]) }} MXN
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import gql from "graphql-tag";
import moment from "moment/moment";
import VueDatePicker from "@vuepic/vue-datepicker";

const MONTO = gql`
query MontoBySucursal($id:ID!
                    $dateFirst:DateTime!
                    $dateLast:DateTime!, $canal:String!) {
    montoBySucursal(id: $id, dateFirst: $dateFirst, dateLast: $dateLast, canal:$canal)
}`;
const MONTO_NO_DATE = gql`
query MontoBySucursalNoDate($id:ID!, $canal:String!) {
    montoBySucursalNoDate(id: $id, canal:$canal)
}`;
const SUCURSALES = gql`
query SucursalesByDinero($dateFirst:DateTime!, $dateLast:DateTime!, $canal:String!) {
    sucursalesByDinero(dateFirst: $dateFirst, dateLast: $dateLast, canal:$canal) {
        id
        name
        isActive
    }
}`;
const SUCURSALES_NO_DATE = gql`
query SucursalesByDineroNoDate($canal:String!) {
    sucursalesByDineroNoDate(canal:$canal) {
        id
        name
        isActive
    }
}`;
export default {
  name: "Porciento_by_cliente",
  components: { VueDatePicker },
  data() {
    return {
      canal: "todo",
      sucursalesByDinero: [],
      ventas: [],
      porcientos: [],
      clientes_type: [],
      total: 0,
      date: [],
      firstDate: null,
      lastDate: null,
      sin_registros: false
    };
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  watch: {
    sucursalesByDinero(value) {
      if (value.length === 0) {
        this.sin_registros = true;
      } else {
        this.sin_registros = false;
        value.forEach(item => {
          if (this.firstDate === null || this.lastDate === null) {
            this.$apollo.query({
              query: MONTO_NO_DATE,
              variables: {
                id: item.id,
                canal: this.canal
              }
            }).then(response => {
              this.ventas[item.id] = response.data.montoBySucursalNoDate;
            });
          } else {
            this.$apollo.query({
              query: MONTO,
              variables: {
                id: item.id,
                dateFirst: this.firstDate,
                dateLast: this.lastDate,
                canal: this.canal
              }
            }).then(response => {
              this.ventas[item.id] = response.data.montoBySucursal;
            });
          }
        });
      }
    },
    date(value) {
      this.firstDate = value[0];
      this.lastDate = value[1];
      this.searchSucursales()
    },
    canal(){
      this.searchSucursales()
    }
  },
  created() {
    this.total = 0;
    this.sin_registros = true;
    this.searchSucursales()
  },
  methods: {
    searchSucursales(){
      if (this.firstDate === null || this.lastDate === null) {
        this.$apollo.query({
          query: SUCURSALES_NO_DATE,
          variables: {
            canal: this.canal
          }
        }).then(response => {
          this.sucursalesByDinero = response.data.sucursalesByDineroNoDate;
        });
      }
      else {
        this.$apollo.query({
          query: SUCURSALES,
          variables: {
            dateFirst: this.firstDate,
            dateLast: this.lastDate,
            canal: this.canal
          }
        }).then(response => {
          this.sucursalesByDinero = response.data.sucursalesByDinero;
        });
      }
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    }
  }
};

</script>
<style scoped>

</style>
