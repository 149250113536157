<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Listados de vehículos para mantenimiento
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon" autocomplete="new-password"
                       placeholder="Buscar vehículo" aria-label="Recipient's username" aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-6 text-center">

            </div>
            <div class="col-3 text-end">

            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" id="tabla_mantenimiento">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Número económico</th>
                <th>Categoría</th>
                <th>Sucursal</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Kilometraje</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in vehiculosPerName" :key="item.id"
                  v-show="!item.concluido">
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.noEcon }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.sucursal.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.placa }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.version.modelo.marca.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.version.modelo.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  {{ item.kilometraje }}
                </td>
                <td class="text-sm font-weight-normal">
                  <span class="text-white p-1 rounded-3"
                        :class="Verificar_estado(item.estadoMantenimiento)">{{ item.estadoMantenimiento
                    }}</span>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openCar(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del Vehículo" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button v-show="item.estadoMantenimiento!=='En proceso'" @click="openModal(item)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Pasar a mantenimiento" data-container="body"
                            data-animation="true" class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">build</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Nuevo">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Pasar vehículo a mantenimiento<i
            @click="closeModal"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <label class="form-label">Fecha de entrada<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fecha_entrada" class="border-secondary border">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mb-3">
            <label class="form-label">Fecha de salida<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm aa"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fecha_salida" class="border-secondary border">
            </VueDatePicker>
          </div>
        </div>
        <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
          <div class="d-flex align-center justify-content-center"><i
            class="material-icons-round opacity-10 me-2">error</i>
            <p class="text-dark">Debe Llenar todos los campos</p></div>
        </div>
        <div v-show="error_disponibilidad" class="text-danger mt-3 text-center p-2"
             style="background-color: rgba(255,19,28,0.08)">
          <div class="d-flex align-center justify-content-center"><i
            class="material-icons-round opacity-10 me-2">error</i>
            <p class="text-dark">Ese auto no esta disponible para mantenimiento en esas fechas</p></div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Crear_Mantenimiento" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Concluir mantenimiento<i @click="closeUpdate"
                                                                                                  class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Descripción del mantenimiento</label>
            <textarea v-model="descripcion" class="form-control"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Terminar" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeCar" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import * as XLSX from "xlsx";

const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const BUSCAR = gql`
          query VehiculosPerName($name:String!) {
    vehiculosPerName(name: $name) {
        id
        noEcon
        anno
        files
        kilometraje
        transmision
        vin
        estadoMantenimiento
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        estado{
        name
        }
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            }
        version{
          name
          modelo{
            name
            marca{
              name
              }
          }
        }
      }
    }`;
const BUSCAR_VEHICULO = gql`
query VehiculosDisponiblesMantenimiento($fechaSalida:DateTime!
$fechaRegreso:DateTime!) {
    vehiculosDisponiblesMantenimiento(fechaSalida: $fechaSalida, fechaRegreso: $fechaRegreso) {
        id
        noEcon
        anno
        transmision
        vin
        placa
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        estatus
        fechaCredito
        fechaCirculacion
        fechaPoliza
        files
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        version {
            id
            name
            isActive
            modelo {
                id
                name
                isActive
                marca {
                    id
                    name
                    isActive
                }
            }
        }
    }
}

`;
const MANTENIMIENTO = gql`mutation EmpezarMantenimiento(
    $vehiculo: Int!
    $fecha_salida: DateTime!
    $fecha_entrada: DateTime!
) {
    empezarMantenimiento(fechaEntrada: $fecha_entrada, vehiculo: $vehiculo, fechaSalida: $fecha_salida) {
        success
        error
    }
}
`;
const TERMINAR_MANTENIMIENTO = gql`
mutation TerminarMantenimiento($descripcion:String!, $id:Int!) {
    terminarMantenimiento(descripcion: $descripcion, id: $id) {
        success
        error
    }
}`;
export default {
  name: "List_Version",
  components: { MaterialButton, VueGoogleAutocomplete, VueDatePicker },
  data() {
    return {
      misearch: "",
      versionPerName: [],
      vehiculosPerName: [],
      showModal: false,
      UpdateModal: false,
      fecha_entrada: null,
      fecha_salida: null,
      sucursal: "",
      vehiculo: 0,
      vehiculos: [],
      error: false,
      car: null,
      showCar: false,
      descripcion: "",
      id: 0,
      error_disponibilidad: ""
    };
  },
  apollo: {
    vehiculosPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    fecha_salida(value) {
      this.error = false;
      this.error_disponibilidad = false;
      this.Buscar_Vehiculo();
    },
    fecha_entrada(value) {
      this.error = false;
      this.error_disponibilidad = false;
      this.Buscar_Vehiculo();
    }
  },
  methods: {
    async Crear_Mantenimiento() {
      if (this.vehiculo === 0 || this.fecha_salida === null || this.fecha_entrada === null) {
        this.error = true;
        return false;
      }
      if (this.Verificar_Vehiculo(this.vehiculo) === false) {
        this.error_disponibilidad = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: MANTENIMIENTO,
        variables: {
          vehiculo: this.vehiculo,
          fecha_salida: this.fecha_salida,
          fecha_entrada: this.fecha_entrada
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        if (response.data.empezarMantenimiento.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Vehículo pasado a mantenimiento"
          });
          this.closeModal();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.empezarMantenimiento.error
          });
          this.closeModal();
        }

      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        this.closeModal();
        Toast.fire({
          icon: "error",
          title: "Error al pasar el vehículo a mantenimiento"
        });
        console.error("La mutación falló:", error);
      });
    },
    openModal(item) {
      this.vehiculo = item.id;
      this.sucursal = item.sucursal.id;
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(id) {
      this.id = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.versionid = 0;
      this.modelo = 0;
      this.marcaid = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.name = "";
      this.versionid = 0;
      this.modelo = 0;
      this.fecha_entrada = null;
      this.fecha_salida = null;
      this.marcaid = 0;
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    Buscar_Vehiculo() {
      if (this.fecha_entrada !== null && this.fecha_salida !== null) {
        if (this.Difechas(this.fecha_entrada, this.fecha_salida) <= 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "La fecha de salida nu puede ser igual o anterior a la fecha de entrada"
          });
          return false;
        }
        this.$apollo.query({
          query: BUSCAR_VEHICULO,
          variables: {
            fechaSalida: this.fecha_salida,
            fechaRegreso: this.fecha_entrada
          }
        }).then(response => {
          this.vehiculos = response.data.vehiculosDisponiblesMantenimiento;
        });
      }
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    openCar(item) {
      this.car = item;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      this.car = null;
      document.body.classList.remove("modal-open");
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    Verificar_estado(estado) {
      if (estado === "Al corriente") {
        return "bg-success";
      }
      if (estado === "En proceso") {
        return "bg-info";
      }
    },
    exportToExcel() {
      const table = document.querySelector("table");
      if (table) {
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "datos_exportados.xlsx");
      } else {
        console.error("No se encontró la tabla");
      }
    },
    Terminar() {
      this.$apollo.mutate({
        mutation: TERMINAR_MANTENIMIENTO,
        variables: {
          id: this.id,
          descripcion: this.descripcion
        }
      }).then(response => {
        if (response.data.terminarMantenimiento.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Mantenimiento terminado con éxito"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al terminar el mantenimiento"
          });
        }
        this.closeUpdate();
      });
    },
    Verificar_Vehiculo(id) {
      var disponibles = [];
      this.vehiculos.forEach(item => {
        disponibles.push(item.id);
      });
      return disponibles.includes(id);
    }

  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}

</style>
