<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Precio de la gasolina
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="fecha" type="date" class="form-control border-dark border-bottom">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openUpdate" class="btn btn-dark align-content-end" type="submit">Nuevo precio
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Usuario</th>
                <th>Precio</th>
                <th>Fecha</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in allGasolina" :key="item.id" class="text-center align-middle">
                <td class="text-wrap">{{ item.user ? item.user.firstName : "" }} {{ item.user ? item.user.lastName : ""
                  }}
                </td>
                <td class="text-wrap">$ {{ item.precio }} MXN</td>
                <td class="text-wrap">{{ formatearFecha(item.fecha) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo precio de la gasolina<i
            @click="closeUpdate"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Precio</label>
            <input class="form-control border-secondary border p-2" v-model="precio" type="number" min="0.01">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un precio</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";
import moment from "moment/moment";

const BUSCAR = gql`
query AllGasolina {
    allGasolina {
        id
        precio
        fecha
        user {
            id
            username
            firstName
            lastName
            email
            isActive
            phone
        }
    }
}
`;
const BUSCAR_FECHA = gql`
          query GasolinaPerFecha($fecha:Date!) {
    gasolinaPerFecha(fecha: $fecha) {
        id
        precio
        fecha
        user {
            id
            username
            firstName
            lastName
            email
            isActive
            phone
        }
    }
}`;
const UPDATE = gql`
  mutation Gasolina ($gasolina:Decimal!, $user:ID!){
    gasolina(gasolina: $gasolina, user:  $user) {
        success
        error
    }
}`;
export default {
  name: "List_Ciudad",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      id: 0,
      precio: 0,
      fecha: null,
      allGasolina: [],
      UpdateModal: false,
      error: false
    };
  },
  watch: {
    fecha(value) {
      if (value === null || value === "") {
        this.$apollo.query({
          query: BUSCAR
        }).then(response => {
          this.allGasolina = response.data.allGasolina;
        });
      } else {
        this.$apollo.query({
          query: BUSCAR_FECHA,
          variables: {
            fecha: value
          }
        }).then(response => {
          this.allGasolina = response.data.gasolinaPerFecha;
        });
      }
    }
  },
  apollo: {
    allGasolina: {
      query: BUSCAR,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async update() {
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            gasolina: this.precio,
            user: this.$store.state.userid
          },
          refetchQueries: [{ query: BUSCAR, variables: { fecha: this.fecha } }]
        }).then(response => {
          if (response.data.gasolina.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Precio actualizado"
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.gasolina.error
            });
          }
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el precio"
          });
          this.closeUpdate();
        });
    },
    openUpdate() {
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.precio = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
