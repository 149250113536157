<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>Categorías con más dinero generado</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Imagen
            </th>
            <th

              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Categoría
            </th>
            <th

              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Total generado
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(categoria, index) in categoriasByDinero" :key="categoria.id" v-show="index<elementos">
            <td class="text-sm font-weight-normal">
              <div class="ms-3"><img class="rounded-circle img-categoria"
                                     :src="this.$store.state.store_url+categoria.image"></div>
            </td>
            <td class="text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">{{ categoria.name }}</div>
            </td>
            <td class="text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">$ {{ numeroFormateado(montos[categoria.id]) }} MXN</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import gql from "graphql-tag";

const MONTO = gql`
query MontoByCategoria($id:ID!) {
    montoByCategoria(id:$id)
}`;
const CATEGORIAS = gql`
query CategoriasByDinero {
    categoriasByDinero {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
        descripcion
    }
}`;
export default {
  name: "OrdersListCard",
  components: {
    MaterialAvatar,
    MaterialButton
  },
  data() {
    return {
      categoriasByDinero: [],
      montos: [],
      elementos: 3
    };
  },
  apollo: {
    categoriasByDinero: {
      query: CATEGORIAS,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    categoriasByDinero(value) {
      value.forEach(item => {
        this.$apollo.query({
          query: MONTO,
          variables: {
            id: item.id
          }
        }).then(response => {
          this.montos[item.id] = response.data.montoByCategoria;
        });
      });
    }
  },
  methods: {
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};
</script>

<style scoped>
.img-categoria {
  height: 60px;
  width: 90px;
  object-fit: cover;
}
</style>
