<template>
  <div>
    <table class="" id="miTabla" style="table-layout: fixed; width: 100%">
      <thead>
      <tr class="text-center">
        <th class="text-sm">Tipo de cliente</th>
        <th class="text-sm">Ventas</th>
        <th class="text-sm">Porciento</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center align-middle" v-for="item in TipoClientePerName" :key="item.id"
          v-show="item.isActive===true">
        <td class="text-sm" style="word-break: break-word;">{{ clientes_type[item.id] }}
        </td>
        <td class="text-sm" style="word-break: break-word;">
          $ {{ numeroFormateado(ventas[item.id]) }} MXN
        </td>
        <td class="text-sm" style="word-break: break-word;">
          {{ ventas[item.id] / total * 100 }} %
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import gql from "graphql-tag";
import moment from "moment/moment";
import query from "apollo-cache-inmemory/lib/fragmentMatcherIntrospectionQuery";

const BUSCAR = gql`
query TipoClientePerName($name:String!) {
    TipoClientePerName(name: $name) {
        id
        name
        isActive
    }
}`;
const VENTAS_TIPO = gql`
query VentasByTypeClient($typeClient:ID!, $sucursal:ID!) {
    ventasByTypeClient(typeClient: $typeClient, sucursal: $sucursal)
}`;
export default {
  name: "Porciento_by_cliente",
  data() {
    return {
      TipoClientePerName: [],
      ventas: [],
      porcientos: [],
      clientes_type: [],
      total: 0
    };
  },
  props: {
    search: {
      type: String,
      required: true
    }
  },
  apollo: {
    TipoClientePerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    TipoClientePerName(value) {
      this.$store.state.Cargando = false;
      if (this.total === 0) {
        value.forEach(item => {
          this.$apollo.query({
            query: VENTAS_TIPO,
            variables: {
              sucursal: this.$store.state.rol === "Administrador" ? 0 : this.$store.state.userSucursal,
              typeClient: item.id
            }
          }).then(response => {
            this.total = this.total + response.data.ventasByTypeClient;
            this.ventas[item.id] = response.data.ventasByTypeClient;
            this.clientes_type[item.id] = item.name;
          });
        });
      }
    }
  },
  created() {
    this.total = 0;
  },
  methods: {
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};

</script>
<style scoped>

</style>
