<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de vehículos reservados
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="row m-3">
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de salida
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm a"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             v-model="salida" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de regreso
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm a"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             v-model="regreso" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-3">
              Categoría
              <select v-model="categoria_id" class="form-control p-2 border-secondary border">
                <option value="0">Todas</option>
                <option v-for="item in allCategoriasAdmin" :key="item.id" :value="item.id" v-show="item.isActive">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2 d-flex flex-column justify-content-end">
              <button @click="Buscar" class="btn btn-dark w-100 mt-4" style="height: 42px" type="submit">
                <i class="fa fa-search me-1"></i>
                Buscar
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" id="miTabla">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Fecha</th>
                <th>No. Folio</th>
                <th>Cliente</th>
                <th>Categoría</th>
                <th>No. Económico</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Cobertura</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="auto in vehiculosReservados" :key="auto.id">
                <td class="text-sm font-weight-normal">
                  {{ auto.reserva.fecha }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.reserva.noReserva }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.reserva.nombre }} {{ auto.reserva.apellidos }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.vehiculo.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.vehiculo.noEcon }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.vehiculo.version.modelo.marca.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ auto.vehiculo.version.modelo.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  Total
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openCar(auto.vehiculo)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del Vehículo" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button @click="openReserva(auto.reserva)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles de la Reserva" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <div v-if="tienePermiso('gestionar_contratos')">
                      <button @click="Contrato(auto)" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Crear contrato" data-container="body"
                              data-animation="true" class="btn btn-primary p-1 ms-1">
                        <i class="material-icons opacity-10">description</i></button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal  DetallesVehiculo">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button @click="closeCar" class="btn btn-dark" type="button">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal DetallesReserva">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles de la reserva<i @click="closeDetalles"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Nombre:</strong> &nbsp;
                {{ this.item.nombre }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Email:</strong> &nbsp;
                {{ this.item.email }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Teléfono:</strong> {{ this.item.telefono }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Renta:</strong><br>
                {{ formatearFecha(this.item.fechaRenta) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Días:</strong> &nbsp;
                {{ this.item.dias }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cobertura {{ item.tipoCobertura }}:</strong> &nbsp;
                $ {{ numeroFormateado(this.item.tarifaDiaria) }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de renta: </strong>$ {{ numeroFormateado(this.total_renta) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Monto total: </strong>$ {{ numeroFormateado(this.monto_total) }} MXN
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Apellidos:</strong> &nbsp;
                {{ this.item.apellidos }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Edad:</strong> &nbsp; {{ this.item.edad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.item.sucursal.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Regreso:</strong><br>
                {{ formatearFecha(this.item.fechaFin) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.item.categoria.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Forma de
                  pago: </strong>{{ this.item.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de servicios extras: </strong>$
                {{ numeroFormateado(this.item.serviciosExtras) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total pagado: </strong>$ {{ numeroFormateado(this.item.total) }} MXN
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Comentarios: </strong>{{ this.item.comentarios ? this.item.comentarios : "" }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="closeDetalles" class="btn btn-dark" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import moment from "moment";
import { mapGetters, mapState } from "vuex";

const BUSCAR = gql`
          query VehiculosPerStatus($status:Int!) {
    vehiculosPerStatus(status: $status) {
        id
        noEcon
        anno
        transmision
        vin
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        estado{
        name
        }
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            }
        version{
          id
          name
          modelo{
            id
            name
            marca{
              id
              name
              }
          }
        }
      }
    }`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const RESERVADOS = gql`query VehiculosReservados(
    $salida: DateTime!
    $regreso: DateTime!
    $sucursal: Int!,
    $categoria:Int!
) {
    vehiculosReservados(
        fechaSalida: $salida
        fechaRegreso: $regreso
        sucursal: $sucursal,
        categoria:$categoria
    ) {
        id
        vehiculo {
            id
            noEcon
            anno
            transmision
            vin
            placa
            isActive
            numeroSerie
            color
            capacidadTanke
            fechaAlta
            estatus
            fechaCredito
            fechaCirculacion
            fechaPoliza
            files
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            version {
                id
                name
                isActive
                modelo {
                    id
                    name
                    isActive
                    marca {
                        id
                        name
                        isActive
                    }
                }
            }
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
        }
        reserva {
            id
            total
            email
            tipoCobertura
            dias
            serviciosExtras
            tarifaDiaria
            comentarios
            fechaRenta
            fechaFin
            nombre
            apellidos
            metodoPago
            telefono
            edad
            pagoId
            origen
            revisado
            noReserva
            fecha
            estado
            cantidad
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
            agente {
                id
                username
                firstName
                lastName
                email
                isActive
                phone
            }
        }
    }
}`;
const RESERVADOS_SALIDA = gql`query VehiculoReservadosSalida(
    $salida: DateTime!
    $sucursal: Int!,
    $categoria:Int!
) {
    vehiculoReservadosSalida(
        fechaSalida: $salida
        sucursal: $sucursal,
        categoria:$categoria
    ) {
        id
        vehiculo {
            id
            noEcon
            anno
            transmision
            vin
            placa
            isActive
            numeroSerie
            color
            capacidadTanke
            fechaAlta
            estatus
            fechaCredito
            fechaCirculacion
            fechaPoliza
            files
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            version {
                id
                name
                isActive
                modelo {
                    id
                    name
                    isActive
                    marca {
                        id
                        name
                        isActive
                    }
                }
            }
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
        }
        reserva {
            id
            total
            email
            tipoCobertura
            dias
            serviciosExtras
            tarifaDiaria
            comentarios
            fechaRenta
            fechaFin
            nombre
            metodoPago
            apellidos
            telefono
            edad
            pagoId
            origen
            revisado
            noReserva
            fecha
            estado
            cantidad
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
            agente {
                id
                username
                firstName
                lastName
                email
                isActive
                phone
            }
        }
    }
}`;
const RESERVADOS_REGRESO = gql`query VehiculoReservadosRegreso(
    $regreso: DateTime!
    $sucursal: Int!,
    $categoria:Int!
) {
    vehiculoReservadosRegreso(
        fechaRegreso: $regreso
        sucursal: $sucursal,
        categoria:$categoria
    ) {
        id
        vehiculo {
            id
            noEcon
            anno
            transmision
            vin
            placa
            isActive
            numeroSerie
            color
            capacidadTanke
            fechaAlta
            estatus
            fechaCredito
            fechaCirculacion
            fechaPoliza
            files
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            version {
                id
                name
                isActive
                modelo {
                    id
                    name
                    isActive
                    marca {
                        id
                        name
                        isActive
                    }
                }
            }
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
        }
        reserva {
            id
            total
            email
            tipoCobertura
            dias
            serviciosExtras
            tarifaDiaria
            comentarios
            fechaRenta
            fechaFin
            metodoPago
            nombre
            apellidos
            telefono
            edad
            pagoId
            origen
            revisado
            noReserva
            fecha
            estado
            cantidad
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
            agente {
                id
                username
                firstName
                lastName
                email
                isActive
                phone
            }
        }
    }
}`;
const ALL_RESERVADOS = gql`
query AllReservados($categoria: Int!, $sucursal: Int!) {
    allReservados(categoria: $categoria, sucursal: $sucursal) {
        id
        vehiculo {
            id
            noEcon
            anno
            transmision
            vin
            placa
            isActive
            numeroSerie
            color
            capacidadTanke
            fechaAlta
            estatus
            fechaCredito
            fechaCirculacion
            fechaPoliza
            files
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            version {
                id
                name
                isActive
                modelo {
                    id
                    name
                    isActive
                    marca {
                        id
                        name
                        isActive
                    }
                }
            }
            titular {
                id
                name
                isActive
            }
            aseguradora {
                id
                name
                isActive
            }
            estado {
                id
                name
                abreviatura
                isActive
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
        }
        reserva {
            id
            total
            email
            tipoCobertura
            dias
            metodoPago
            serviciosExtras
            tarifaDiaria
            comentarios
            fechaRenta
            fechaFin
            nombre
            apellidos
            telefono
            edad
            pagoId
            origen
            revisado
            noReserva
            fecha
            estado
            cantidad
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
            }
            sucursal {
                id
                name
                phone
                contactPhone
                address
                postalCod
                openHour
                closeHour
                isActive
            }
            agente {
                id
                username
                firstName
                lastName
                email
                isActive
                phone
            }
        }
    }
}`;
const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
export default {
  name: "List_Categorias",
  components: { MaterialButton, VueDatePicker },
  data() {
    return {
      misearch: "",
      vehiculosReservados: [],
      allSucursales: [],
      allCategoriasAdmin: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      status: 0,
      carid: 0,
      preview: null,
      imagen: null,
      car: null,
      sucursal: null,
      ImageModal: false,
      ImageUpdateModal: false,
      salida: null,
      regreso: null,
      categoria_id: 0,
      showDetalles: false,
      item: null

    };
  },
  apollo: {
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    vehiculosReservados() {
      this.$store.state.Cargando = false;
    }
  },
  methods: {
    Buscar() {
      this.$store.state.Cargando = true;
      if (this.Difechas(this.salida, this.regreso) <= 0) {
        this.regreso = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de salida"
        });
        this.$store.state.Cargando = false;
        return false;
      }
      if (this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: RESERVADOS,
          variables: {
            salida: this.salida,
            regreso: this.regreso,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(response => {
          this.vehiculosReservados = response.data.vehiculosReservados;
          if (this.vehiculosReservados.length === 0) {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen registros"
            });
          }
        });
      } else if (this.salida !== null) {
        this.$apollo.query({
          query: RESERVADOS_SALIDA,
          variables: {
            salida: this.salida,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(response => {
          this.vehiculosReservados = response.data.vehiculoReservadosSalida;
          if (this.vehiculosReservados.length === 0) {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen registros"
            });
          }
        });
      } else if (this.regreso !== null) {
        this.$apollo.query({
          query: RESERVADOS_REGRESO,
          variables: {
            regreso: this.regreso,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(response => {
          this.vehiculosReservados = response.data.vehiculoReservadosRegreso;
          if (this.vehiculosReservados.length === 0) {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen registros"
            });
          }
        });
      } else {
        this.$apollo.query({
          query: ALL_RESERVADOS,
          variables: {
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(response => {
          this.vehiculosReservados = response.data.allReservados;
          if (this.vehiculosReservados.length === 0) {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen registros"
            });
          }
          this.$store.state.Cargando = false;
        });
      }
    },
    openReserva(item) {
      this.item = item;
      this.monto_total = parseFloat(item.serviciosExtras) + (parseFloat(item.tarifaDiaria) * parseInt(item.dias));
      this.total_renta = parseFloat(item.tarifaDiaria) * parseInt(item.dias);
      this.showDetalles = true;
      document.body.classList.add("modal-open");
    },
    openCar(item) {
      this.car = item;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeDetalles() {
      this.item = null;
      this.showDetalles = false;

    },
    closeCar() {
      this.car = null;
      this.showCar = false;
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    Contrato(item) {
      this.$store.state.reserva = item.reserva;
      this.$store.state.vehiculo = item.vehiculo;
      this.$store.state.anterior = "Reservados";
      this.$router.push("/contratos/nuevo");
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    }
  },
  mounted() {
    this.$store.state.Cargando = true;
    this.sucursal = this.$store.state.userSucursal ? this.$store.state.userSucursal : 0;
    if (this.salida !== null && this.regreso !== null) {
      this.$apollo.query({
        query: RESERVADOS,
        variables: {
          salida: this.salida,
          regreso: this.regreso,
          sucursal: this.sucursal,
          categoria: this.categoria_id
        }
      }).then(response => {
        this.vehiculosReservados = response.data.vehiculosReservados;
        if (this.vehiculosReservados.length === 0) {
          this.$store.state.Cargando = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "No existen registros"
          });
        }
      });
    } else {
      this.$apollo.query({
        query: ALL_RESERVADOS,
        variables: {
          sucursal: this.sucursal,
          categoria: this.categoria_id
        }
      }).then(response => {
        this.vehiculosReservados = response.data.allReservados;
        if (this.vehiculosReservados.length === 0) {
          this.$store.state.Cargando = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "No existen registros"
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(["tienePermiso"])
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
