<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Reportes
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="search" type="text" class="form-control input-icon"
                       v-show="reporte!=='ventas_sucursales'"
                       placeholder="Buscar" aria-label="Recipient's username"
                       aria-describedby="button-addon2">

              </div>
            </div>
            <div class="col-6"></div>
            <div class="col-3">
              <div class="input-group">
                <select class="form-select" v-model="reporte">
                  <option value="tanque_prepago">Tanques prepagados</option>
                  <option value="porciento_by_tipo">Ventas por tipo de cliente</option>
                  <option value="ventas_sucursales">Ventas por sucursales</option>
                  <option value="contratos_extras">Contratos con servicios extras</option>
                  <option value="contratos_deducible">Contratos con 0 deducibles</option>
                  <option value="vehiculos_km">Vehículos con mayor kilometraje</option>
                  <option value="mantenimiento_pendiente">Vehiculos con mantenimiento pendiente</option>
                </select>

              </div>
            </div>
          </div>
          <Contratos_tanque_lleno :search="search" v-if="reporte==='tanque_prepago'"></Contratos_tanque_lleno>
          <Contratos_extras :search="search" v-if="reporte==='contratos_extras'"></Contratos_extras>
          <Contratos_deducibles :search="search" v-if="reporte==='contratos_deducible'"></Contratos_deducibles>
          <Porciento_by_cliente :search="search" v-if="reporte==='porciento_by_tipo'"></Porciento_by_cliente>
          <Sucursales_by_Ventas v-if="reporte==='ventas_sucursales'"></Sucursales_by_Ventas>
          <Vehiculos_by_km :search="search" v-if="reporte==='vehiculos_km'"></Vehiculos_by_km>
          <Vehiculos_mantenimiento_pendiente :search="search" v-if="reporte==='mantenimiento_pendiente'"></Vehiculos_mantenimiento_pendiente>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import noImagen from "@/assets/img/NoImage.jpg";
import Contratos_tanque_lleno from "@/components/reportes/Contratos_tanque_lleno.vue";
import Porciento_by_cliente from "@/components/reportes/Porciento_by_cliente.vue";
import Sucursales_by_Ventas from "@/components/reportes/Sucursales_by_Ventas.vue";
import Contratos_extras from "@/components/reportes/Contratos_extras.vue";
import Vehiculos_by_km from "@/components/reportes/Vehiculos_by_km.vue";
import Vehiculos_mantenimiento_pendiente from "@/components/reportes/Vehiculos_mantenimiento_pendiente.vue";
import Contratos_deducibles from "@/components/reportes/Contratos_deducibles.vue";

export default {
  name: "Cotizaciones",
  components: {
    Contratos_tanque_lleno,
    MaterialButton,
    VueGoogleAutocomplete,
    VueDatePicker,
    Porciento_by_cliente,
    Sucursales_by_Ventas,
    Contratos_extras,
    Vehiculos_by_km,
    Vehiculos_mantenimiento_pendiente,
    Contratos_deducibles
  },
  data() {
    return {
      reporte: "tanque_prepago",
      search: ""
    };
  },
  mounted() {
    this.$store.state.Cargando = true;
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.input-icon:focus {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-icon1 {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.btn-primary1 {
  background-color: #FFA633;
  border: 2px solid #FFA633 !important;
  box-shadow: inset 0 0 0 0 #fff;
  color: #fff;
}

.btn-primary1:hover {
  color: rgba(255, 255, 255, 0.8);
}

.btn-issy {
  background-color: #fff;
  border: 2px solid #FFA633 !important;
  color: #FFA633;
  box-shadow: inset 0 0 0 0 #fff;
}

.btn-issy:hover {
  color: rgba(255, 166, 51, 0.87);
}

.text-gray {
  color: #888888;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.services_extras_container {
  height: 150px;
  overflow-y: hidden;
}

.services_extras {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 550px) {
  .modal-content1 {
    width: 80%;

  }

  .modal-content {
    width: 100%;
    margin-top: 200px;
  }

  .services_extras_container {
    height: 300px;
    overflow-y: hidden;
  }

  .services_extras {
    height: 30%;
    display: block;
    justify-content: center;
    align-items: center;
  }
}

@media print {
  .modal {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  /* Añade aquí los estilos adicionales para la impresión */
}
</style>
