<template>
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Comentarios del cliente
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Comentario" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Estado</th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Teléfono</th>
                <th>Sucursal</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in comentarioPerName" :key="item.id" class="text-center align-middle"
                  :class="item.revisado?'':'text-bold'">
                <td class="text-wrap">
                  <span v-if="!item.revisado" class="bg-info text-white p-1 rounded-3">Nuevo</span>
                  <span v-else class="bg-secondary text-white p-1 rounded-3">Revisado</span>
                </td>
                <td class="text-wrap">{{ item.nombre ? item.nombre : "" }}</td>
                <td class="text-wrap">{{ item.correo ? item.correo : "" }}</td>
                <td class="text-wrap">{{ item.telefono ? item.telefono : "" }}</td>
                <td class="text-wrap">{{ item.sucursal ? item.sucursal.name : "" }}</td>
                <td class="text-wrap">{{ item.fecha ? formatearFecha(item.fecha) : "" }}</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openDetalles(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Ver comentario" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-comment-alt me-2"></i>Comentarios del cliente<i @click="closeDetalles"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          {{ comentario }}
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="closeDetalles" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import moment from "moment";
import { mapState } from "vuex";

const BUSCAR = gql`
          query ComentarioPerName($name: String!) {
    comentarioPerName(name: $name) {
        id
        nombre
        correo
        telefono
        comentarios
        fecha
        revisado
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
    }
}`;
const REVISADA = gql`
mutation ComentarioRevisado($id:Int!) {
    comentarioRevisado(id: $id) {
        success
        errors
    }
}`;
export default {
  name: "Comentarios",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      item: null,
      comentarioPerName: [],
      showModal: false,
      showDetalles: false,
      comentario: ""
    };
  },
  apollo: {
    comentarioPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    openDetalles(item) {
      this.comentario = item.comentarios;
      this.showDetalles = true;
      document.body.classList.add("modal-open");
      this.$apollo.mutate({
        mutation: REVISADA,
        variables: {
          id: item.id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
    },
    closeDetalles() {
      this.item = null;
      this.showDetalles = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('@/assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
