<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Ventas mensuales por año</h6>
        <VueDatePicker v-model="year" year-picker class="w-25" locale="es"/>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-primary"></i>
          <span class="text-xs text-dark">Ventas mensuales</span>
        </span>
      </div>
    </div>
    <div class="p-3 card-body">
      <canvas id="ventas_mes" class="chart-canvas" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import DefaultLineChart from "@/examples/Charts/DefaultLineChart.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import gql from "graphql-tag";
import Chart from "chart.js/auto";

const VENTAS = gql`
query VentasByMes($anno:Int!) {
    ventasByMes(year: $anno)
}`;
export default {
  name: "RevenueChartCard",
  components: {
    DefaultLineChart,
    VueDatePicker
  },
  apollo: {
    ventasByMes: {
      query: VENTAS,
      variables() {
        return {
          anno: this.year
        };
      }
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      ventasByMes: [],
      ventas_mensuales: []
    };
  },
  watch: {
    ventasByMes(value) {
      this.ventas_mensuales = [];
      const totales = this.ventasByMes;
      totales.forEach(item => {
        this.ventas_mensuales.push(item);
        if (this.ventas_mensuales.length === 12) {
          this.Crear_grafico();
        }
      });
    }
  },
  methods: {
    Crear_grafico() {
      var chart = document.getElementById("ventas_mes").getContext("2d");

      var gradientStroke2 = chart.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart("ventas_mes");
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }
      new Chart(chart, {
        type: "line",
        data: {
          labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
          datasets: [
            {
              label: "Ventas mensuales",
              tension: 0.4,
              pointRadius: 2,
              pointBackgroundColor: "#cb0c9f",
              borderColor: "#cb0c9f",
              borderWidth: 3,
              backgroundColor: gradientStroke2,
              data: this.ventas_mensuales,
              maxBarThickness: 6
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          interaction: {
            intersect: false,
            mode: "index"
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7"
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10
              }
            }
          }
        }
      });
    }
  }
};
</script>
