<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de servicios extras
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar servicio" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo servicio</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Ícono</th>
                <th>Servicio</th>
                <th>Precio</th>
                <th>Pago diario</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in serviciosPerName" :key="item.id">
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  <div class="ms-3"><img class="rounded-circle icon-service"
                                         :src="this.$store.state.store_url+item.icono"></div>
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  <div class="ms-3">{{ item.name }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  <div class="ms-3">{{ item.precio }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  <div class="ms-3">{{ item.diario ? "Si" : "No" }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="item.isActive?'':'text-danger'">
                  <div class="ms-3">{{ item.isActive ? "Si" : "No" }}</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="item.isActive" @click="Desactive(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar servicio extra" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="Active(item.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Activar servicio extra" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button
                      @click="openUpdate(item.id, item.name, item.precio, item.diario)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Editar servicio extra" data-container="body" data-animation="true"
                      class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button
                      @click="openImagen(item.icono, item.id)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Agregar o actualizar ícono de servicio extra" data-container="body" data-animation="true"
                      class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">add_photo_alternate</i></button>
                    <button @click="DeleteCategoria(item.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar servicio extra" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevoServicio">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo servicio<i @click="closeModal"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <label class="form-label">Servicio</label>
            <input v-model="servicio" class="form-control border-bottom border-dark p-2 mb-2"
                   type="text">
            <label class="form-label">Precio</label>
            <input v-model="precio" class="form-control border-bottom border-dark p-2 mb-2"
                   type="number">
            <input class="form-check-inline" type="checkbox" v-model="diario">
            <label class="form-label">Pago diario</label>
          </div>
          <div v-show="error" class="text-danger mt-2 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="addServicio" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal UpdateServicio">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Editar servicio extra<i @click="closeUpdate"
                                                                                               class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Servicio</label>
            <input v-model="servicio" class="form-control border-bottom border-dark p-2 mb-2"
                   type="text">
            <label class="form-label">Precio</label>
            <input v-model="precio" class="form-control border-bottom border-dark p-2 mb-2"
                   type="number">
            <input class="form-check-inline" type="checkbox" v-model="diario">
            <label class="form-label">Pago diario</label>
          </div>
          <div v-show="error" class="text-danger mt-3 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="UpdateServicio" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevaImagen">
    <div v-if="ImageModal" :class="['modal', { 'show': ImageModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo ícono de servicio extra<i
            @click="closeImageModal"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Seleccione un ícono</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom" @change="onImagenSeleccionada">
            <img class="img-fluid" :src="preview" style="height: 250px">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe seleccionar una imagen</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeImageModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarIcono" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import axios from "axios";
import { mapState } from "vuex";

const DESACTIVE = gql`
mutation DesactiveServicio($id:Int!) {
    desactiveServicio(id: $id) {
        success
    }
}`;
const BUSCAR = gql`
          query ServiciosPerName($name:String!) {
    serviciosPerName(name: $name) {
        id
        name
        diario
        precio
        icono
        isActive
    }
}`;
const ACTIVE = gql`
mutation ActiveServicio($id:Int!) {
    activeServicio(id: $id) {
        success
    }
}
`;
const INSERT = gql`
  mutation CreateServicioextra($name:String!, $precio:Decimal!, $diario:Boolean!) {
    createServicioextra(name: $name, precio: $precio, diario:$diario) {
        error
        success
    }
}`;
const UPDATE = gql`
  mutation UpdateServicio($id: Int!, $name: String!, $precio: Decimal!,  $diario:Boolean!) {
    updateServicio(id: $id, name: $name, precio: $precio, diario:$diario) {
        error
        success
    }
}`;
const DELETE = gql`
       mutation DeleteServicio($id:Int!) {
    deleteServicio(id: $id) {
        error
        success
    }
}

`;
export default {
  name: "List_Servicios",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      serviciosPerName: [],
      showModal: false,
      UpdateModal: false,
      ImageModal: false,
      imagen: null,
      servicio: "Escriba el nombre de un servicio",
      precio: 0,
      id: 0,
      diario: false,
      preview: null,
      error: false
    };
  },
  apollo: {
    serviciosPerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addServicio() {
      if (this.servicio === "" || this.precio === 0) {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: INSERT,
          variables: {
            name: this.servicio,
            precio: this.precio,
            diario: this.diario
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.createServicioextra.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: "Servicio extra creado correctamente"
            });
            this.reset();
            this.closeModal();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: response.createServicioextra.error
            });
            this.reset();
            this.closeModal();
          }
        });
    },
    async UpdateServicio() {
      if (this.servicio === "" || this.precio === 0) {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            name: this.servicio,
            precio: this.precio,
            diario: this.diario,
            id: this.id
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          if (response.data.updateServicio.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Servicio extra actualizado correctamente"
            });
            this.closeUpdate();
            this.reset();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateServicio.error
            });
            this.reset();
            this.closeUpdate();
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la Categoría"
          });
          console.error("La mutación falló:", error);
        });
    },
    async DeleteCategoria(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.deleteServicio.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Servicio extra eliminado"
            });
          }
        }
      });
    },
    async Desactive(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveServicio.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Servicio extra desactivado"
        });
      }
    },
    async Active(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeServicio.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Servicio extra activado"
        });
      }
    },
    openModal() {
      this.reset();
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(id, name, precio, diario) {
      this.id = id;
      this.servicio = name;
      this.precio = precio;
      this.diario = diario;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.reset();
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.reset();
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    openImagen(imagen, id) {
      if (imagen) {
        this.preview = this.$store.state.store_url + imagen;
      }
      this.id = id;
      this.ImageModal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    guardarIcono() {
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: Int) {\\n  iconoServicio(id: $id, icono: $file) {\\n    error  }\\n}","variables": {"id": ${this.id},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.imagen, this.imagen.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Ícono actualizado"
        });
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {
        });
        this.reset();
        this.closeImageModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.$apollo.mutate({
          mutation: BUSCAR,
          variables: {
            name: this.misearch
          }
        }).then(response => {
        });
        this.reset();
        this.closeImageModal();
      });
    },
    closeImageModal() {
      this.reset();
      this.ImageModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.imagen = event.target.files[0];
      const file = event.target.files[0];
      this.preview = URL.createObjectURL(file);
    },
    reset() {
      this.precio = 0;
      this.servicio = "";
      this.imagen = null;
      this.preview = null;
      this.diario = false;
      this.id = 0;
      this.error = false;
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
.icon-service{
  width: 50px;
  object-fit: cover;
}
</style>
