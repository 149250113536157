<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de vehículos disponibles
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="row m-3">
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de salida
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             placeholder="Seleccione una fecha" id="salida_disponible" format="dd/MM/yyyy hh:mm a"
                             v-model="salida" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de regreso
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             placeholder="Seleccione una fecha" id="regreso_disponible" format="dd/MM/yyyy hh:mm a"
                             v-model="regreso" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2">
              Categoría
              <select v-model="categoria_id" class="form-control p-2 border-secondary border">
                <option value="0">Todas</option>
                <option v-for="item in allCategoriasAdmin" :key="item.id" :value="item.id" v-show="item.isActive">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2 d-flex flex-column justify-content-end">
              <button @click="Buscar" class="btn btn-dark w-100 mt-4" style="height: 42px" type="submit">
                <i class="fa fa-search me-1"></i>
                Buscar
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" id="miTabla">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Categoría</th>
                <th>Coberturas</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Año</th>
                <th>No. económico</th>
                <th>Sucursal</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="auto in items" :key="auto.id"
                  :class="auto.isActive?'':'text-danger'">
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  Total: {{ this.temporada ? auto.categoria.totalAlta : auto.categoria.totalBaja
                  }}<br>
                  Tradicional:
                  {{ this.temporada ? auto.categoria.tradicionalAlta : auto.categoria.tradicionalBaja
                  }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.version.modelo.marca.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.version.modelo.name }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.anno }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.noEcon }}
                </td>
                <td class="text-sm font-weight-normal" :class="auto.isActive?'':'text-danger'">
                  {{ auto.sucursal.name }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <div v-if="tienePermiso('gestionar_contratos')">
                      <button @click="Contrato(auto)" data-bs-toggle="tooltip" v-if="esHoy(salida)"
                              data-bs-placement="top" title="Crear contrato" data-container="body"
                              data-animation="true" class="btn btn-primary p-1 ms-1">
                        <i class="material-icons opacity-10">description</i></button>
                    </div>
                    <div v-if="tienePermiso('gestionar_reservas')">
                      <button @click="Reserva(auto)" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Crear reserva" data-container="body"
                              data-animation="true" class="btn btn-secondary p-1 ms-1">
                        <i class="material-icons opacity-10">description</i></button>
                    </div>
                    <button @click="openCar(auto)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalles del Vehículo" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
      <div class="row">
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <!--          Mostrando {{ items.length }} de-->
          <!--          {{ cant }}-->
        </div>
        <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
          <p class="me-3" style="margin-bottom: 0px">Elementos por página</p>
          <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
            <option :value=10>10</option>
            <option :value=50>50</option>
            <option :value=100>100</option>
          </select>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage"
                  :disabled="this.hasPreviousPage===false">
            <i class="material-icons opacity-10">skip_previous</i></button>
          <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="this.hasNextPage===false">
            <i class="material-icons opacity-10">skip_next</i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del Vehículo<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">No. Económico:</strong> &nbsp;
                    {{ this.car.noEcon }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.car.categoria.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Año:</strong> &nbsp; {{ this.car.anno }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Marca:</strong> &nbsp;
                    {{ this.car.version.modelo.marca.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Modelo:</strong> &nbsp;
                    {{ this.car.version.modelo.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Versión:</strong> &nbsp;
                    {{ this.car.version.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Titular:</strong> &nbsp;
                    {{ this.car.titular.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Aseguradora:</strong> &nbsp; {{ this.car.aseguradora.name }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Placa:</strong> &nbsp; {{ this.car.placa }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estatus:</strong> &nbsp;
                    {{ this.car.estatus }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Cap. del Tanque:</strong> &nbsp;
                    {{ this.car.capacidadTanke }} lts
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Estado Emplacado:</strong> &nbsp;
                    {{ this.car.estado.name }}
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-sm-12">
                <ul class="list-group">
                  <li class="pt-0 text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Color:</strong> &nbsp;
                    {{ this.car.color }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Número de Serie:</strong> &nbsp;
                    {{ this.car.numeroSerie }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Fecha de Alta:</strong> {{ this.car.fechaAlta }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Transmisión:</strong> {{ this.car.transmision }}
                  </li>
                  <li class="text-sm border-0 list-group-item ps-0">
                    <strong class="text-dark">Sucursal:</strong> &nbsp;
                    {{ this.car.sucursal.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row text-end m-3">
              <div class="col-8"></div>
              <div class="col-4">
                <button @click="closeCar" class="mt-3 btn btn-dark text-end">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import moment from "moment";
import { ref } from "vue";

import flatpickr from "flatpickr";
import { mapGetters, mapState } from "vuex";

const BUSCAR = gql`
          query VehiculosPerStatus($status:Int!) {
    vehiculosPerStatus(status: $status) {
        id
        noEcon
        anno
        transmision
        vin
        imagen
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        estado{
        name
        }
        minimo
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            }
        version{
          id
          name
          modelo{
            id
            name
            marca{
              id
              name
              }
          }
        }
      }
    }`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const DISPONIBLES = gql`query VehiculosDisponibles(
    $salida: DateTime!
    $regreso: DateTime!
    $categoria:Int!
) {
    vehiculosDisponibles(
        fechaSalida: $salida
        fechaRegreso: $regreso
        categoria:$categoria
    ) {
        id
        noEcon
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        version {
            id
            name
            isActive
            modelo {
              id
              name
              marca {
                id
                name
              }
            }
        }
        anno
        transmision
        vin
        titular {
            id
            name
            isActive
        }
        aseguradora {
            id
            name
            isActive
        }
        placa
        estado {
            id
            name
            abreviatura
            isActive
        }
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
        estatus
        fechaCredito
        fechaCirculacion
        fechaPoliza
        files
    }
}`;
const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
const ALL_DISPONIBLES = gql`
query AllDisponibles($categoria: Int!) {
    allDisponibles(categoria: $categoria) {
        id
        noEcon
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        version {
            id
            name
            isActive
            modelo {
              id
              name
              marca {
                id
                name
              }
            }
        }
        anno
        transmision
        vin
        titular {
            id
            name
            isActive
        }
        aseguradora {
            id
            name
            isActive
        }
        placa
        estado {
            id
            name
            abreviatura
            isActive
        }
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
        estatus
        fechaCredito
        fechaCirculacion
        fechaPoliza
        files
    }
}

`;
const SEARCH_ALL_DISPONIBLES = gql`
query AllDisponibles(
    $before: String!
    $after: String!
    $first: Int!
    $categoria: Int!
) {
    allDisponibles(
        categoria: $categoria
        before: $before
        after: $after
        first: $first
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;
const SEARCH_ALL_DISPONIBLES_PREV = gql`
query AllDisponibles(
    $before: String!
    $first: Int!
    $categoria: Int!
) {
    allDisponibles(
        categoria: $categoria
        before: $before
        after:""
        first: $first
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;
const SEARCH_ALL_DISPONIBLES_NEXT = gql`
query AllDisponibles(
    $after:String!
    $first:Int!
    $categoria: Int!
) {
    allDisponibles(
        categoria: $categoria
        after: $after
        before:""
        first: $first
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;

const SEARCH_DISPONIBLES = gql`
query AllDisponibles(
    $before: String!
    $after: String!
    $first: Int!
    $categoria: Int!
    $fechaSalida:DateTime!
    $fechaRegreso:DateTime!
) {
    vehiculosDisponibles(
        categoria: $categoria
        before: $before
        after: $after
        first: $first
        fechaSalida:$fechaSalida
        fechaRegreso:$fechaRegreso
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;
const SEARCH_DISPONIBLES_PREV = gql`
query AllDisponibles(
    $before: String!
    $first: Int!
    $categoria: Int!
    $fechaSalida:DateTime!
    $fechaRegreso:DateTime!
) {
    vehiculosDisponibles(
        categoria: $categoria
        before: $before
        after:""
        first: $first
        fechaSalida:$fechaSalida
        fechaRegreso:$fechaRegreso
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;
const SEARCH_DISPONIBLES_NEXT = gql`
query AllDisponibles(
    $after:String!
    $first:Int!
    $categoria: Int!
    $fechaSalida:DateTime!
    $fechaRegreso:DateTime!
) {
    vehiculosDisponibles(
        categoria: $categoria
        after: $after
        before:""
        first: $first
        fechaSalida:$fechaSalida
        fechaRegreso:$fechaRegreso
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noEcon
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                      id
                      name
                      marca {
                        id
                        name
                      }
                    }
                }
                anno
                transmision
                vin
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                placa
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
            }
        }
    }
}
`;
const TEMPORADA = gql`
query TemporadaAlta($fechaInicio: Date!, $fechaFin: Date!) {
    temporadaAlta(fechaInicio: $fechaInicio, fechaFin: $fechaFin)
}`;
export default {
  name: "List_Categorias",
  components: { MaterialButton, VueDatePicker, flatpickr },
  data() {
    return {
      misearch: "",
      vehiculosPerStatus: [],
      vehiculosDisponibles: [],
      allCategoriasAdmin: [],
      allSucursales: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      status: 0,
      carid: 0,
      temporada: false,
      preview: null,
      imagen: null,
      car: null,
      sucursal: 0,
      ImageModal: false,
      ImageUpdateModal: false,
      salida: null,
      regreso: null,
      categoria_id: 0,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: []
    };
  },
  apollo: {
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    vehiculosDisponibles() {
      this.$store.state.Cargando = false;
    },
    salida(value) {
      if (value !== null) {
        document.getElementById("salida_disponible").classList.remove("error_fecha");
      }
    },
    regreso(value) {
      if (value !== null) {
        document.getElementById("regreso_disponible").classList.remove("error_fecha");
      }
    },

    items() {
      this.$store.state.Cargando = false;
    },
    cant() {
      this.loadDatas(true);
    }
  },
  methods: {
    esHoy(item) {
      const fecha = new Date(item);
      const hoy = new Date();
      return fecha.getDate() === hoy.getDate() &&
        fecha.getMonth() === hoy.getMonth() &&
        fecha.getFullYear() === hoy.getFullYear();
    },
    Buscar() {
      this.$store.state.Cargando = true;
      if (this.Difechas(this.salida, this.regreso) <= 0) {
        this.regreso = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de salida"
        });
        this.$store.state.Cargando = false;
        return false;
      }
      if (this.salida !== null && this.regreso !== null) {
        this.Temporada_alta();
        this.loadDatas(true);
      } else {
        this.$store.state.Cargando = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar todos los campos"
        });
      }
    },
    async openCar(car) {
      this.car = car;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    Contrato(item) {
      var validacion = true;
      if (this.salida === null) {
        validacion = false;
        document.getElementById("salida_disponible").classList.add("error_fecha");
      }
      if (this.regreso === null) {
        validacion = false;
        document.getElementById("regreso_disponible").classList.add("error_fecha");
      }
      if (validacion) {
        this.$store.state.salida = this.salida;
        this.$store.state.regreso = this.regreso;
        this.$store.state.vehiculo = item;
        this.$store.state.anterior = "Disponibles";
        this.$router.push("/contratos/nuevo");
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar los campos para realizar un contrato"
        });
      }
    },
    Reserva(item) {
      var validacion = true;
      if (this.salida === null) {
        validacion = false;
        document.getElementById("salida_disponible").classList.add("error_fecha");
      }
      if (this.regreso === null) {
        validacion = false;
        document.getElementById("regreso_disponible").classList.add("error_fecha");
      }
      if (validacion) {
        this.$store.state.salida = this.salida;
        this.$store.state.regreso = this.regreso;
        this.$store.state.reserva_disponibles = true;
        this.$store.state.categoria = item.categoria;
        this.$router.push("/transacciones/administracion");
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar los campos para realizar una reserva"
        });
      }
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Temporada_alta() {
      if (this.fechaRenta !== null && this.fechaFin !== null) {
        this.$apollo.query({
          query: TEMPORADA,
          variables: {
            fechaInicio: this.salida.toISOString().slice(0, 10),
            fechaFin: this.regreso.toISOString().slice(0, 10)
          }
        }).then(response => {
          this.temporada = response.data.temporadaAlta;
        });
      }
    },

    loadDatas(init = false) {
      this.$store.state.Cargando = true;

      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      if (this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: SEARCH_DISPONIBLES,
          variables: {
            before: before,
            after: next,
            first: this.cant,
            categoria: this.categoria_id,
            fechaSalida: this.salida,
            fechaRegreso: this.regreso
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          if (init === true) {
            this.hasNextPage = resp.data.vehiculosDisponibles.pageInfo.hasNextPage;
            this.hasPreviousPage = resp.data.vehiculosDisponibles.pageInfo.hasPreviousPage;
          }
          this.startCursor = resp.data.vehiculosDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.vehiculosDisponibles.pageInfo.endCursor;
          this.items = resp.data.vehiculosDisponibles.edges.map(value => {
            return value.node;
          });
        });
      } else {
        this.$apollo.query({
          query: SEARCH_ALL_DISPONIBLES,
          variables: {
            before: before,
            after: next,
            first: this.cant,
            categoria: this.categoria_id
          },
          fetchPolicy: "network-only"
        }).then(resp => {
          if (init === true) {
            this.hasNextPage = resp.data.allDisponibles.pageInfo.hasNextPage;
            this.hasPreviousPage = resp.data.allDisponibles.pageInfo.hasPreviousPage;
          }
          this.startCursor = resp.data.allDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.allDisponibles.pageInfo.endCursor;
          this.items = resp.data.allDisponibles.edges.map(value => {
            return value.node;
          });
        });
      }
    },
    nextPage() {
      this.$store.state.Cargando = true;
      if (this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: SEARCH_DISPONIBLES_NEXT,
          variables: {
            name: this.misearch,
            after: this.endCursor,
            first: this.cant,
            sucursal: this.sucursal,
            categoria: this.categoria_id,
            fechaSalida: this.salida,
            fechaRegreso: this.regreso
          }
        }).then(resp => {
          this.lastEndCursor = "";
          this.lastStartCursor = this.endCursor;
          this.hasNextPage = resp.data.vehiculosDisponibles.pageInfo.hasNextPage;
          this.hasPreviousPage = true;
          this.startCursor = resp.data.vehiculosDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.vehiculosDisponibles.pageInfo.endCursor;
          this.items = resp.data.vehiculosDisponibles.edges.map(value => {
            return value.node;
          });
        }).catch((err) => {
          alert(err);
        }).finally(() => {
          this.$store.state.Cargando = false;
        });
      } else {
        this.$apollo.query({
          query: SEARCH_ALL_DISPONIBLES_NEXT,
          variables: {
            name: this.misearch,
            after: this.endCursor,
            first: this.cant,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(resp => {
          this.lastEndCursor = "";
          this.lastStartCursor = this.endCursor;
          this.hasNextPage = resp.data.allDisponibles.pageInfo.hasNextPage;
          this.hasPreviousPage = true;
          this.startCursor = resp.data.allDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.allDisponibles.pageInfo.endCursor;
          this.items = resp.data.allDisponibles.edges.map(value => {
            return value.node;
          });
        }).catch((err) => {
          alert(err);
        }).finally(() => {
          this.$store.state.Cargando = false;
        });
      }
    },
    prevPage() {
      this.$store.state.Cargando = true;
      if (this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: SEARCH_DISPONIBLES_PREV,
          variables: {
            name: this.misearch,
            before: this.startCursor,
            first: this.cant,
            sucursal: this.sucursal,
            categoria: this.categoria_id,
            fechaSalida: this.salida,
            fechaRegreso: this.regreso
          }
        }).then(resp => {
          this.lastEndCursor = this.startCursor;
          this.lastStartCursor = "";
          this.hasNextPage = true;
          this.hasPreviousPage = resp.data.vehiculosDisponibles.pageInfo.hasPreviousPage;
          this.startCursor = resp.data.vehiculosDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.vehiculosDisponibles.pageInfo.endCursor;
          this.items = resp.data.vehiculosDisponibles.edges.map(value => {
            return value.node;
          });
        }).catch((err) => {
          this.toast.fire({
            icon: "error",
            title: err.message
          });
        }).finally(() => {
          this.$store.state.Cargando = false;
        });
      } else {
        this.$apollo.query({
          query: SEARCH_ALL_DISPONIBLES_PREV,
          variables: {
            name: this.misearch,
            before: this.startCursor,
            first: this.cant,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(resp => {
          this.lastEndCursor = this.startCursor;
          this.lastStartCursor = "";
          this.hasNextPage = true;
          this.hasPreviousPage = resp.data.allDisponibles.pageInfo.hasPreviousPage;
          this.startCursor = resp.data.allDisponibles.pageInfo.startCursor;
          this.endCursor = resp.data.allDisponibles.pageInfo.endCursor;
          this.items = resp.data.allDisponibles.edges.map(value => {
            return value.node;
          });
        }).catch((err) => {
          this.toast.fire({
            icon: "error",
            title: err.message
          });
        }).finally(() => {
          this.$store.state.Cargando = false;
        });
      }
    }
  },
  mounted() {
    this.$store.state.Cargando = true;
    this.sucursal = this.$store.state.userSucursal ? this.$store.state.userSucursal : 0;
    this.loadDatas(true);
  },
  computed: {
    ...mapGetters(["tienePermiso"])
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
