<template>
  <div class="mt-4 col-sm-4 mt-sm-0">
    <div class="card h-card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-7 text-start">
            <p class="mb-1 text-sm">
              Ventas por sucursal
            </p>
            <p class="mb-0 text-sm font-weight-bolder">$ {{ numeroFormateado(totalVentas) }} MXN</p>
          </div>
          <div class="col-5">
            <div class="dropdown text-end">
              <a
                id="dropdownUsers1"
                href="javascript:;"
                class="cursor-pointer text-secondary border border-secondary p-1 d-flex align-items-center justify-content-between"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showMenu = !showMenu"
                :class="{show:showMenu}"
              >
                <span class="text-xs text-secondary">{{ sucursal_name }}</span><span class="text-xs text-secondary"><i class="material-icons">arrow_drop_down</i> </span>
              </a>
              <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end"
                :class="{show:showMenu}"
                aria-labelledby="dropdownUsers1"
              >
                <li>
                  <a @click="Change_Sucursal(0, 'Todas')"
                     class="dropdown-item border-radius-md"
                  >Todas</a
                  >
                </li>
                <li v-for="(drop, index) in allSucursales" :key="index" v-show="drop.isActive">
                  <a @click="Change_Sucursal(drop.id, drop.name)"
                     class="dropdown-item border-radius-md"
                  >{{ drop.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

const TOTAL_VENTAS = gql`
query TotalVentas($sucursal:ID!) {
    totalVentas(sucursal: $sucursal)
}`;
const SUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}`;
export default {
  name: "DefaultStatisticsCard",
  data() {
    return {
      showMenu: false,
      allSucursales: [],
      totalVentas: 0,
      sucursal: 0,
      sucursal_name: "Todas"
    };
  },
  apollo: {
    totalVentas: {
      query: TOTAL_VENTAS,
      variables() {
        return {
          sucursal: this.sucursal
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: SUCURSALES,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    Change_Sucursal(id, name) {
      this.sucursal = id;
      this.sucursal_name = name;
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};
</script>
