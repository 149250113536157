<template>
  <div class="mt-4 col-sm-4 mt-sm-0">
    <div class="card h-card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-7 text-start">
            <p class="mb-1 text-sm">
              Ventas por fecha
            </p>
            <p class="mb-0 text-sm font-weight-bolder">$ {{ numeroFormateado(ventasByDate) }} MXN</p>
          </div>
          <div class="col-5">
            <div class="dropdown text-end">
              <a
                id="dropdownUsers1"
                href="javascript:;"
                class="cursor-pointer text-secondary border border-secondary p-1 d-flex align-items-center justify-content-between"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showMenu = !showMenu"
                :class="{show:showMenu}"
              >
                <span class="text-xs text-secondary">{{ sucursal_name }}</span>
                <span class="text-xs text-secondary"><i class="material-icons">arrow_drop_down</i> </span>
              </a>
              <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end"
                :class="{show:showMenu}"
                aria-labelledby="dropdownUsers1"
              >
                <li>
                  <a @click="Change_Sucursal(0, 'Todas')"
                     class="dropdown-item border-radius-md"
                  >Todas</a
                  >
                </li>
                <li v-for="(drop, index) in allSucursales" :key="index" v-show="drop.isActive">
                  <a @click="Change_Sucursal(drop.id, drop.name)"
                     class="dropdown-item border-radius-md"
                  >{{ drop.name }}</a
                  >
                </li>
              </ul>
            </div>
            <VueDatePicker v-model="date" range auto-apply locale="es"
                           :multi-calendars="{ solo: true }" format="dd/MM/yyyy">
            </VueDatePicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const TOTAL_VENTAS = gql`
query VentasByDate(
    $dateFirst:DateTime!
    $dateLast:DateTime!
    $sucursal:ID!
) {
    ventasByDate(dateFirst: $dateFirst, dateLast: $dateLast, sucursal: $sucursal)
}`;
const SUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}`;
export default {
  components: {
    VueDatePicker
  },
  name: "DefaultStatisticsCard",
  data() {
    return {
      showMenu: false,
      allSucursales: [],
      ventasByDate: 0,
      sucursal: 0,
      sucursal_name: "Todas",
      firstDate: null,
      lastDate: null,
      date: null
    };
  },
  watch: {
    date(value) {
      this.firstDate = value[0];
      this.lastDate = value[1];
    }
  },
  created() {
    const firstDate = new Date();
    const lastDate = new Date(new Date().setDate(firstDate.getDate() + 7));
    this.date = [firstDate, lastDate];
  },
  apollo: {
    ventasByDate: {
      query: TOTAL_VENTAS,
      variables() {
        return {
          sucursal: this.sucursal,
          dateFirst: this.firstDate,
          dateLast: this.lastDate
        };
      },
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: SUCURSALES,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    Change_Sucursal(id, name) {
      this.sucursal = id;
      this.sucursal_name = name;
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  }
};
</script>
