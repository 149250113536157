<template>
  <div id="basic-info" class="card mt-4">
    <div class="card-header bg-gradient-warning">
      <h5 style="color:#fff">Información Básica</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Nombre</label>
          <input
            class="form-control border-bottom"
            v-model="firstName"
            placeholder="Nombre"
          />
        </div>
        <div class="col-6">
          <label class="form-label">Apellidos</label>
          <input
            class="form-control border-bottom"
            v-model="lastName"
            placeholder="Apellidos"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-6">
          <label class="form-label">Usuario</label>
          <input
            class="form-control border-bottom"
            v-model="username"
            placeholder="Usuario"
          />
        </div>
        <div class="col-6">
          <label class="form-label">Correo</label>
          <input
            type="email"
            class="form-control border-bottom"
            v-model="email"
            placeholder="Correo"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-6">
          <label class="form-label">Teléfono</label>
          <input
            class="form-control border-bottom"
            v-model="phone"
            placeholder="Telefono"
          />
        </div>
        <div class="col-6">
          <label class="form-label">Ciudad</label>
          <select v-model="ciudad" class="form-select">
            <option disabled value="0">Seleccione una ciudad</option>
            <option v-for="city in allCiudades" :value="city.id" v-show="city.isActive">{{ city.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <label class="form-label">Sucursal</label>
          <select v-model="sucursal" class="form-select">
            <option disabled value="0">Seleccione una sucursal</option>
            <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}</option>
          </select>
        </div>
        <div class="col-6">
          <material-button
            class="float-end mt-6 mb-0"
            color="dark"
            variant="gradient"
            size="sm"
            @click="updateUsuario"
          >Actualizar Usuario
          </material-button>
        </div>
      </div>
      <div class="row mt-4">
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header bg-gradient-warning">
      <h5 style="color: #fff">Cambiar Contraseña</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <label class="form-label">Contraseña anterior</label>
        <input
          v-model="old_password"
          class="form-control border-bottom"
          id="currentPassword"
          type="password"
          placeholder="Contraseña anterior"
        />
      </div>
      <div class="mb-3">
        <label class="form-label">Contraseña nueva</label>
        <input v-model="newpassword" class="form-control border-bottom" id="newPassword" type="password"
               placeholder="Contraseña nueva" />
      </div>
      <div class="mb-3">
        <label class="form-label">Repita la contraseña</label>
        <input
          v-model="repeat_password"
          class="form-control border-bottom"
          id="confirmPassword"
          type="password"
          placeholder="Repita la contraseña"
        />
      </div>
      <h5 class="mt-5">Requisitos de Contraseña</h5>
      <p class="text-muted mb-2">
        Por favor siga esta guia para establecer una contraseña segura:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">Uno o mas caracteres especiales</span>
        </li>
        <li>
          <span class="text-sm">Minimo 6 caracteres</span>
        </li>
        <li>
          <span class="text-sm">Uno o mas numeros</span>
        </li>
        <li>
          <span class="text-sm">Cámbiala a menudo</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="resetPassword"
      >Cambiar Contraseña
      </material-button
      >
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import gql from "graphql-tag";
import Swal from "sweetalert2";

const USER_ID = gql`
query UserPerId ($id:Int!){
    userPerId(id: $id) {
        id
        username
        firstName
        lastName
        email
        isActive
        phone
        groups {
            id
            name
        }
        sucursal {
            id
            name
        }
        ciudad {
            id
            name
        }
    }
}

`;
const UPDATE_USER = gql`
  mutation($id:ID!,$username: String!, $email: String!, $firstName: String!,
   $lasName: String!, $ciudad: ID!, $sucursal: ID!, $phone: String!){
    updateUser(
        id:$id
        firstName: $firstName
        email: $email
        lastName: $lasName
        username: $username
        ciudad: $ciudad
        sucursal: $sucursal
        phone: $phone
    ){
      success
      errors
    }
  }`;
const RESET_PASSWORD = gql`
mutation($old:String!, $new:String!, $repeat:String!, $id:ID!){
          resetMypassword(passwordOld:$old, password:$new,password1:$repeat, id:$id){
            success
            errors
          }
        }
`;
const ALL_CITYS = gql`
query AllCiudades {
    allCiudades {
        id
        name
        isActive
    }
}
`;
const ALL_SUCURSALES = gql`query AllSucursales {
    allSucursales {
        id
        name
        phone
        contactPhone
        address
        postalCod
        openHour
        closeHour
        isActive
        ciudad {
            id
            name
            isActive
            estado {
                id
                name
                abreviatura
                isActive
            }
        }
    }
}
`;
export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    MaterialBadge,
    MaterialAvatar,
    MaterialInput
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      ciudad: 0,
      phone: "",
      sucursal: 0,
      userid: this.$store.state.userid,
      old_password: "",
      newpassword: "",
      repeat_password: "",
      allCiudades: [],
      allSucursales: []
    };
  },
  apollo: {
    allCiudades: {
      query: ALL_CITYS,
      fetchPolicy: "cache-and-network"
    },
    allSucursales: {
      query: ALL_SUCURSALES,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async updateUsuario() {
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: this.userid,
            username: this.username,
            firstName: this.firstName,
            lasName: this.lastName,
            email: this.email,
            ciudad: this.ciudad,
            sucursal: this.sucursal,
            phone: this.phone
          }
        }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Usuario actualizado"
        });
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al actualizar el usuario"
        });
        console.error("La mutación falló:", error);
      });
    },
    async resetPassword() {
      await this.$apollo.mutate({
        mutation: RESET_PASSWORD,
        variables: {
          id: this.userid,
          new: this.newpassword,
          old: this.old_password,
          repeat: this.repeat_password
        }
      }).then(response => {
        this.$store.state.IsLogin = true;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Contraseña cambiada"
        });
      }).catch(error => {
        this.$swal({
          text: error,
          icon: "error",
          customClass: {
            confirmButton: "bg-warning"
          }
        });
      });
    }
  },
  async created() {
    await this.$apollo.query({
      query: USER_ID,
      variables: {
        id: this.userid
      }
    }).then(response => {
      this.username = response.data.userPerId.username;
      this.firstName = response.data.userPerId.firstName;
      this.lastName = response.data.userPerId.lastName;
      this.email = response.data.userPerId.email;
      this.phone = response.data.userPerId.phone;
      this.ciudad = response.data.userPerId.ciudad ? response.data.userPerId.ciudad.id : 0;
      this.sucursal = response.data.userPerId.sucursal ? response.data.userPerId.sucursal.id : 0;
    });

  }
};
</script>
