<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header d-flex justify-content-between align-items-center">
      <h6>Sucursales con más dinero generado</h6>
      <VueDatePicker v-model="date" range auto-apply locale="es" class="w-50"
                     :multi-calendars="{ solo: true }" format="dd/MM/yyyy">
      </VueDatePicker>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Sucursal
            </th>
            <th

              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Total generado
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(categoria, index) in sucursalesByDinero" :key="categoria.id" v-show="index<elementos">
            <td class="text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">{{ categoria.name }}</div>
            </td>
            <td class="text-dark text-xxs font-weight-bolder opacity-7">
              <div class="ms-3">$ {{ numeroFormateado(montos[categoria.id]) }} MXN</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import gql from "graphql-tag";
import VueDatePicker from "@vuepic/vue-datepicker";

const MONTO = gql`
query MontoBySucursal($id:ID!
                    $dateFirst:DateTime!
                    $dateLast:DateTime!) {
    montoBySucursal(id: $id, dateFirst: $dateFirst, dateLast: $dateLast, canal:"todo")
}`;
const AGENTES = gql`
query SucursalesByDinero($dateFirst:DateTime!, $dateLast:DateTime!) {
    sucursalesByDinero(dateFirst: $dateFirst, dateLast: $dateLast, canal:"todo") {
        id
        name
    }
}`;
export default {
  name: "OrdersListCard",
  components: {
    VueDatePicker,
    MaterialAvatar,
    MaterialButton
  },
  data() {
    return {
      sucursalesByDinero: [],
      montos: [],
      elementos: 3,
      date: [],
      firstDate: null,
      lastDate: null
    };
  },
  apollo: {
    sucursalesByDinero: {
      query: AGENTES,
      variables() {
        return {
          dateFirst: this.firstDate,
          dateLast: this.lastDate
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    sucursalesByDinero(value) {
      value.forEach(item => {
        this.$apollo.query({
          query: MONTO,
          variables: {
            id: item.id,
            dateFirst: this.firstDate,
            dateLast: this.lastDate
          }
        }).then(response => {
          this.montos[item.id] = response.data.montoBySucursal;
        });
      });
    },
    date(value) {
      this.firstDate = value[0];
      this.lastDate = value[1];
    }
  },
  created() {
    const firstDate = new Date();
    const lastDate = new Date(new Date().setDate(firstDate.getDate() + 7));
    this.date = [firstDate, lastDate];
  },
  methods: {
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    }
  }
};
</script>

<style scoped>
.img-categoria {
  height: 60px;
  width: 90px;
  object-fit: cover;
}
</style>
