<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <input v-model="firstName" id="firstName" placeholder="First Name" class="form-control"/>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <input v-model="lasName" id="lastName" placeholder="Last Name" class="form-control"/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <input v-model="username" id="username" placeholder="Username" class="form-control"/>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <input
            class="form-control"
            id="email"
            type="email"
            placeholder="Email Address"
            v-model="email"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <input
            class="form-control"
            id="password"
            type="password"
            placeholder="Password"
            v-model="password"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <input
            class="form-control"
            id="repeatPassword"
            type="password"
            placeholder="Repeat Password"
            v-model="password1"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.registrar()"
        >Send
        </material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import gql from "graphql-tag";
import Swal from "sweetalert2";

const INSERT_USER=gql`
            mutation($username:String!,$email:String!, $firstName:String!, $lasName:String!, $password:String!,$password1:String!){
              register(username:$username, email:$email, firstName:$firstName,lastName:$lasName, password1:$password, password2:$password1){
                success,
                errors,
                token,
                refreshToken
              }
            }
          `
export default {
  name: "UserInfo",
  components: {
    MaterialInput,
    MaterialButton
  },
  data() {
    return {
        username: "",
        firstName: "",
        lasName: "",
        email: "",
        password: "",
        password1: ""
    };
  },
  methods: {
    async registrar() {
      const { data } = await this.$apollo
        .mutate({
          mutation: INSERT_USER,
          variables: {
            username: this.username,
            firstName: this.firstName,
            lasName: this.lasName,
            email: this.email,
            password: this.password,
            password1: this.password1
          },
    })
      if (data.register.success){
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'User Created'
        })
        this.username=""
        this.firstName=""
        this.lasName=""
        this.email=""
        this.password=""
        this.password1=""
      }
      else {
        this.$swal({
          text: 'Error',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-warning'
          }
        })
      }
  }
}
};
</script>
