<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Tipos de Clientes
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Tipo de Cliente" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Tipo de Cliente
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Tipo de Cliente</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="tipo in TipoClientePerName" :key="tipo.id">
                <td :class="tipo.isActive?'':'text-danger'">{{ tipo.name }}</td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="tipo.isActive" @click="Desactive(tipo.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Tipo de Cliente" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="Active(tipo.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Activar Tipo de Cliente" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button @click="openUpdate(tipo.name, tipo.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Actualizar Tipo de Cliente" data-container="body" data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button @click="Delete(tipo.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Tipo de Cliente" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Tipo de Cliente<i @click="closeModal"
                                                                                      class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Tipo de Cliente</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="name">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un Tipo de Cliente</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Add" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Tipo de Cliente<i @click="closeUpdate"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control mb-3 border-dark border-bottom" v-model="name">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir un Tipo de Cliente</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Update" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapState } from "vuex";

const DESACTIVE = gql`
mutation($id:ID!){
  desactiveTipocliente(id: $id) {
        success
    }
}
`;
const BUSCAR = gql`
          query TipoClientePerName($name:String!) {
    TipoClientePerName(name: $name) {
        id
        name
        isActive
    }
}`;
const ACTIVE = gql`
mutation ActiveTipocliente($id:ID!) {
    activeTipocliente(id: $id) {
        success
    }
}
`;
const INSERT = gql`
  mutation CreateTipocliente($name:String!) {
    createTipocliente(name: $name) {
        success
    }
}
  `;
const UPDATE = gql`
  mutation UpdateTipocliente($id:ID!, $name:String!) {
    updateTipocliente(id: $id, name: $name) {
        success
    }
}
  `;
const DELETE = gql`
mutation EliminarTipocliente($id:ID!) {
    eliminarTipocliente(id: $id) {
        success
    }
}
`;
export default {
  name: "List_Estado",
  components: { MaterialButton, VueGoogleAutocomplete },
  data() {
    return {
      misearch: "",
      name: "",
      TipoClientePerName: [],
      showModal: false,
      id: 0,
      UpdateModal: false,
      error: false
    };
  },
  apollo: {
    TipoClientePerName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.misearch
        };
      },
      fetchPolicy: "cache-and-network"
    }
  },
  mounted() {
    this.$store.state.Cargando = true;
  },
  watch: {
    TipoClientePerName() {
      this.$store.state.Cargando = false;
    }
  },
  methods: {
    async Add() {
      if (this.name === "") {
        this.error = true;
        return false;
      }
      this.$apollo.mutate({
        mutation: INSERT,
        variables: {
          name: this.name
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      }).then(response => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Tipo de Cliente creado"
        });
        this.closeModal();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Error al crear el Tipo de Cliente"
        });
        console.error("La mutación falló:", error);
      });
    },
    async Update() {
      if (this.name === "") {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: {
            name: this.name,
            id: this.id
          },
          refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
        }).then(response => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Tipo de Cliente actualizado"
          });
          this.closeUpdate();
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar el Tipo de Cliente"
          });
          console.error("La mutación falló:", error);
        });
    },
    async Delete(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            },
            refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
          });
          if (data.eliminarTipocliente.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Tipo de Cliente eliminado"
            });
          }
          else
          {
            this.$swal({
              text:"Este Tipo de Cliente está siendo usado",
              icon:'error',
              customClass: {
                confirmButton: 'bg-warning'
              }
            })
          }
        }
      });
    },
    async Desactive(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.desactiveTipocliente.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "warning",
          title: "Tipo de Cliente desactivado"
        });
      }
    },
    async Active(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        },
        refetchQueries: [{ query: BUSCAR, variables: { name: this.misearch } }]
      });
      if (data.activeTipocliente.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Tipo de Cliente activado"
        });
      }
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.name="";
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    openUpdate(name, id) {
      this.name = name;
      this.id = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.id = 0;
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
