<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Permisos
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon" placeholder="Buscar permiso"
                       aria-label="Recipient's username" aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
<!--              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nuevo Permiso</button>-->
            </div>
          </div>
          <div class="table-responsive">
            <table id="order-list" class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in permissionsPerName" :key="item.id" class="text-center align-middle">
                <td class="text-sm font-weight-normal">
                  {{ item.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.description }}
                </td>
                <td class="text-sm font-weight-normal">
                  <div class="btn-group pt-3" role="group">
                      <button class="btn btn-info p-1 ms-1">
                        <i @click="openUpdate(item.name, item.description, item.id)" class="material-icons opacity-10">edit</i>
                      </button>
                    </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="divModal">
      <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
        <div class="modal-content">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nuevo Permiso<i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i></h4>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="form-label">Nombre</label>
              <input v-model="name" class="form-control border-bottom border-dark p-2 mb-3" placeholder="Escriba el nombre del Permiso" type="text">
              <label class="form-label">Descripción</label>
              <input v-model="description" class="form-control border-bottom border-dark p-2" placeholder="Escriba la descripción del Permiso" type="text">
              <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
                <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                  <p class="text-dark">Debe llenar los campos</p></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex justify-content-start">
              <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="addPermiso" class="btn btn-dark" type="button">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divModal">
      <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
        <div class="modal-content">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-pencil-square me-2"></i>Editar Permiso<i @click="closeUpdate" class="material-icons-round opacity-10 modal-icon">close</i></h4>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="form-label">Permiso</label>
              <input disabled v-model="name" class="form-control border-bottom border-dark p-2 mb-3" type="text">
              <label class="form-label">Descripción</label>
              <input v-model="description" class="form-control border-bottom border-dark p-2" type="text">
            </div>
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex justify-content-start">
              <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="UpdatePermiso" class="btn btn-dark" type="button">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
const CREAR_PERMISO = gql`
mutation($name:String!,$description:String!){
    createPermission(name:$name,description:$description, ){
      success
    }
  }
`;
const DELETE_PERMISO = gql`
mutation($id:ID!){
    deletePermission(id:$id){
      success
    }
  }
`;
const UPDATE_PERMISO = gql`
mutation($id:ID!, $name:String!, $description:String!){
  updatePermission(id:$id, name:$name, description: $description){
    success
  }
}
`;
const SEARCH_PERMISO=gql`
            query($name:String!){
              permissionsPerName(name:$name){
                name
                id
                description
              }
            }
`;
export default {
  name: "List_Permisos",
  components: { Modal, MaterialButton },
  data() {
    return {
      misearch: '',
      description:'',
      name:'',
      permisoid:0,
      showModal: false,
      UpdateModal: false,
      error:false,
      permissionsPerName: []
    }
  },
  apollo: {
    permissionsPerName: {
      query: SEARCH_PERMISO,
      variables(){
        return{
          name: this.misearch
        }
      },
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    async addPermiso() {
        await this.$apollo
              .mutate({
                mutation: CREAR_PERMISO,
                variables: {
                  name: this.name,
                  description: this.description
                },
                refetchQueries: [{ query: SEARCH_PERMISO, variables:{name:this.misearch} }]
              }).then(response=>{
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Permiso creado"
              });
              this.closeModal()
              }).catch(error=>{
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al crear el Permiso"
              });
              this.closeModal()
              })
            },
    async UpdatePermiso() {
        await this.$apollo
              .mutate({
                mutation: UPDATE_PERMISO,
                variables: {
                  name: this.name,
                  description: this.description,
                  id: this.permisoid
                },
                refetchQueries: [{ query: SEARCH_PERMISO, variables:{name:this.misearch} }]
              }).then(response=>{
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Permiso actualizado"
              });
              this.closeUpdate()
              }).catch(error=>{
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al crear el Permiso"
              });
            this.closeUpdate()
              })
          },
    async DeletePermiso(id) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showDenyButton:true,
        showCancelButton: true,
        reverseButtons:true,
        showConfirmButton:false,
        cancelButtonText:"Cancelar",
        denyButtonText: 'Si, eliminar!',
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE_PERMISO,
            variables: {
              id: id
            },
            refetchQueries: [{ query: SEARCH_PERMISO, variables:{name:this.misearch} }]
          });
          if (data.deletePermission.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Permiso eliminado"
            });
          }
        }
      });
    },
    openModal() {
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, descripcion, id) {
      this.name = name;
      this.permisoid = id;
      this.description=descripcion
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.name = "";
      this.permisoid = 0;
      this.description=''
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
