<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Gestionar Permisos de Roles
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-4 pe-5">
              <select v-model="rol" class="form-select p-2" aria-label="Default select example">
                <option disabled selected value="0">Seleccione un Rol</option>
                <option v-for="(item, index) in allGroups" :key="index" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-4 ps-5">
              <select v-bind:disabled="rol === 0" v-model="permiso" class="form-select p-2"
                      aria-label="Default select example">
                <option disabled selected value="0">Seleccione un Permiso</option>
                <option v-for="(item, index) in allPermissions" :key="index" v-show="VerificarPermiso(item)"
                        :value="item.id">{{ item.description }}
                </option>
              </select>
            </div>
            <div class="col-4 text-end">
              <button v-bind:disabled="rol === 0" @click="addPermiso(parseInt(rol), parseInt(permiso))"
                      class="btn btn-dark align-content-end" type="submit">Adicionar Permiso
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table id="order-list" class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in permissionsPerGroup" :key="item.id">
                <td class="text-sm font-weight-normal">
                  {{ item.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.description }}
                </td>
                <td class="text-sm font-weight-normal">
                  <div class="btn-group pt-3" role="group">
                    <div>
                      <button v-bind:disabled="rol === 0" @click="removePermiso(parseInt(rol),item.id)"
                              class="btn btn-danger p-1 ms-1">
                        <i class="material-icons opacity-10">delete</i></button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";

const GET_PERMISOS = gql`
{
 allPermissions{
  id
  name
  description
}
}
`;
const GET_PERMISOS_ROL = gql`
query($id:Int!){
  permissionsPerGroup(id:$id){
    name
    id
    description
  }
}
`;
const GET_ROLES_QUERY = gql`
query {
  allGroups{
    id
    name
    permission{
      id
      name
      description
    }
  }
}
`;
const ADD_PERMISO = gql`
mutation($grupo:ID!,$permiso:ID!){
  addPermissions(groupId:$grupo, permissionId:$permiso){
    success
    errors
  }
}
`;
const REMOVE_PERMISO = gql`
mutation($grupo:ID!,$permiso:ID!){
  removePermissions(groupId:$grupo, permissionId:$permiso){
    success
    errors
  }
}
`;
export default {
  name: "List_Users",
  components: { Modal, MaterialButton },
  data() {
    return {
      permissionsPerGroup: [],
      allPermissions: [],
      allGroups: [],
      rol: 0,
      permiso: 0,
      prop: true
    };
  },
  apollo: {
    allPermissions: {
      query: GET_PERMISOS,
      fetchPolicy: "cache-and-network"
    },
    allGroups: {
      query: GET_ROLES_QUERY,
      fetchPolicy: "cache-and-network"
    },
    permissionsPerGroup: {
      query: GET_PERMISOS_ROL,
      variables() {
        return {
          id: parseInt(this.rol)
        };
      }
    }
  },
  computed() {
    return this.rol === 0 ? this.prop = false : this.prop = true;
  },
  methods: {
    async addPermiso(grupo, permiso) {
      const { data } = await this.$apollo.mutate({
        mutation: ADD_PERMISO,
        variables: {
          grupo: grupo,
          permiso: permiso
        },
        refetchQueries: [{ query: GET_PERMISOS_ROL, variables: { id: parseInt(this.rol) } }]
      });
      if (data.addPermissions.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "success",
          title: "Permiso adicionado"
        });
        this.permiso = 0;
      } else {
        this.$swal({
          text: data.addPermissions.errors,
          icon: "error",
          customClass: {
            confirmButton: "bg-warning"
          }
        });
      }
    },
    async removePermiso(grupo, permiso) {
      const { data } = await this.$apollo.mutate({
        mutation: REMOVE_PERMISO,
        variables: {
          grupo: grupo,
          permiso: permiso
        },
        refetchQueries: [{ query: GET_PERMISOS_ROL, variables: { id: parseInt(this.rol) } }]
      });
      if (data.removePermissions.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: "error",
          title: "Permiso eliminado"
        });
      } else {
        this.$swal({
          text: data.removePermissions.errors,
          icon: "error",
          customClass: {
            confirmButton: "bg-warning"
          }
        });
      }
    },
    VerificarPermiso(item) {
      var verificado = true;
      this.permissionsPerGroup.forEach(elemento => {
        if (elemento.id === item.id) {
          verificado = false;
        }
      });
      return verificado;
    }
  }
};
</script>
