<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Ventas por clientes</h6>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <div class="chart">
            <canvas id="ventas_clientes" class="chart-canvas" height="300"></canvas>
          </div>
        </div>
        <div class="my-auto col-5">
          <span class="badge badge-md badge-dot me-4 d-block text-start" v-for="(item, index) in tipos_cliente"
                :key="index">
            <i :class="backgroundColor[index]"></i>
            <span class="text-xs text-dark">{{ item }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/Informes/card_admin/PieChart.vue";
import gql from "graphql-tag";
import Chart from "chart.js/auto";

const TIPOS = gql`
query AllTipocliente {
    allTipocliente {
        id
        name
        isActive
    }
}`;
const VENTAS_TIPO = gql`
query VentasByTypeClient($typeClient:ID!
$sucursal:ID!) {
    ventasByTypeClient(typeClient: $typeClient, sucursal: $sucursal)
}`;
const SUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}`;
export default {
  name: "ChannelsChartCard",
  components: {
    PieChart
  },
  data() {
    return {
      allTipocliente: [],
      tipos_cliente: [],
      showMenu: false,
      allSucursales: [],
      sucursal: 0,
      porcientos: [],
      sucursal_name: "Todas",
      valores: [],
      backgroundColor: ["bg-info", "bg-primary", "bg-dark", "bg-secondary"]
    };
  },
  apollo: {
    allSucursales: {
      query: SUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allTipocliente: {
      query: TIPOS,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    allTipocliente(value) {
      this.valores = [];
      this.tipos_cliente = [];
      value.forEach(item => {
        this.tipos_cliente.push(item.name);
        this.$apollo.query({
          query: VENTAS_TIPO,
          variables: {
            typeClient: item.id,
            sucursal: this.sucursal
          }
        }).then(response => {
          const valor = parseFloat(response.data.ventasByTypeClient);
          this.valores.push(valor);
          if (this.valores.length === value.length) {
            this.Cargar_Ventas_Clientes();
          }
        });
      });
    }
  },
  created() {

  },
  methods: {
    Cargar_Ventas_Clientes() {
      var pieChart = document.getElementById("ventas_clientes").getContext("2d");

      let chartStatus = Chart.getChart("ventas_clientes");
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }
      new Chart(pieChart, {
        type: "pie",
        data: {
          labels: this.tipos_cliente,
          datasets: [
            {
              label: "valores",
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#03A9F4", "#e91e63", "#3A416F", "#a8b8d8"],
              data: this.valores,
              fill: false
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          interaction: {
            intersect: false,
            mode: "index"
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                display: false
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                display: false
              }
            }
          }
        }
      });
    }
  }
};
</script>
