<template>
  <div class="mt-4 col-sm-3 mt-sm-0">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-7 text-start">
            <p class="mb-1 text-sm text-capitalize font-weight-bold">
              {{ title }}
            </p>
            <h5 class="mb-0 font-weight-bolder">{{ count }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleCard",
  props: {
    title: {
      type: String,
      default: "Default Statistics Card",
    },
    count: {
      type: String,
      default: "",
    },
  },
};
</script>
