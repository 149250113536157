<template>
  <div v-if="agente" class="py-4 container-fluid">
    <Dashboard_agente></Dashboard_agente>
  </div>
  <div v-else class="py-4 container-fluid">
    <Dashboard_Administrador></Dashboard_Administrador>
  </div>
</template>

<script>
import ChannelsChartCard from "../../components/Informes/card_admin/ChannelsChartCard.vue";
import RevenueChartCard from "../../components/Informes/card_admin/RevenueChartCard.vue";
import HorizontalBarChart from "@/examples/Charts/HorizontalBarChart.vue";
import DefaultStatisticsCard from "./components/DefaultStatisticsCard.vue";
import OrdersListCard from "@/examples/Cards/OrdersListCard.vue";

// images
import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import AU from "@/assets/img/icons/flags/AU.png";
import setTooltip from "@/assets/js/tooltip.js";
import Layout from "@/views/Plantilla/layout.vue";
import Dashboard_agente from "@/components/Informes/Dashboard_agente.vue";
import Dashboard_Administrador from "@/components/Informes/Dashboard_Administrador.vue";

export default {
  name: "Sales",
  components: {
    Dashboard_Administrador,
    Dashboard_agente,
    Layout,
    ChannelsChartCard,
    RevenueChartCard,
    HorizontalBarChart,
    DefaultStatisticsCard,
    OrdersListCard
  },
  data() {
    return {
      agente: false
    };
  },
  mounted() {
    this.agente = this.$store.state.rol !== "Super Administrador";
    setTooltip(this.$store.state.bootstrap);
  }
};
</script>
