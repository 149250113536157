<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3 fixed-start ms-3 bg-gradient-dark"
    >
    <div class="sidenav-header bg-gradient-warning">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" :to="{ name: 'Dashboard' }">
        <img
          :src="logo"
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <span class="ms-1 font-weight-bold text-white"
          >Administraci&oacute;n</span
        >
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo_amarillo.png";

export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
};
</script>
<style scoped>

</style>
