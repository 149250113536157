<template>
  <div v-if="isAutenticated">
    <sidenav
      color="warning"
      custom_class="warning"
      class="fixed-start"
    />
    <main
      class="main-content position-relative max-height-vh-100 h-100"
    >
      <!-- nav -->
      <navbar
        color="opacity-8"
        :min-nav="navbarMinimize"
      />
      <div class="Cargando">
        <div v-if="this.$store.state.Cargando" :class="['modal_cargando', { 'show': this.$store.state.Cargando }]"
             @transitionend="onTransitionEnd">
          <div class="spinner"></div>
        </div>
      </div>
      <router-view />
      <app-footer />
    </main>
  </div>
  <div v-else>
    <Login></Login>
  </div>
</template>
<script>
import Sidenav from "@/components/Sidenav";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import Login from "@/views/Seguridad/Login.vue";


export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    Login
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "isAuth",
      "Cargando"
    ]),
    isAutenticated() {
      return this.isAuth && this.$route.name !== "Login";
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showUser) {
        this.$emit("close");
      }
    }
  },
  created() {
    this.$store.state.Cargando = false;
    if (!this.isAuth || !$cookies.get("token")) {
      this.$store.state.isAuth = false;
      this.$store.state.userid = null;
      this.$store.state.userSucursal = null;
      this.$store.state.username = null;
      this.$store.state.email = null;
      this.$store.state.firstName = null;
      this.$store.state.lastName = null;
      this.$store.state.rol = null;
      this.$router.push("/");
    }
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];
    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  }
};
</script>
<style>
.modal_cargando {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal_cargando {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal_cargando.show {
  opacity: 1;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 6px solid #fff; /* Color del borde superior */
  border-top: 6px solid #ff6900; /* Color del borde */
  border-radius: 50%;
  animation: spin 2s linear infinite; /* Duración de la animación */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
