<template>
  <sidenav color="warning"
           custom_class="warning"
           class="fixed-start"
  />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <navbar
      :class="navbarFixed, absolute"
      color="opacity-8"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
  </main>
</template>

<script>
import Sidenav from "@/components/Sidenav/index.vue";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "layout",
  components: {
    Sidenav,
    Navbar,
    AppFooter
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ])
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"])
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'principal-layout')
  }
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #66bb6a 0%,
    #43a047 100%
  ) !important;
}
</style>