<template>
  <div class="col-12">
    <h5 class="ms-3">Other Products</h5>
    <div class="table table-responsive">
      <table class="table mb-0 align-items-center">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Product
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Price
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Review
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Availability
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Id
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="px-2 py-1 d-flex">
                <div>
                  <img
                    src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg"
                    class="avatar avatar-md me-3"
                    alt="table image"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Christopher Knight Home</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="mb-0 text-sm text-secondary">$89.53</p>
            </td>
            <td>
              <div class="rating ms-lg-n4">
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">star_outline</i>
              </div>
            </td>
            <td class="text-sm align-middle">
              <div class="mx-auto progress">
                <div
                  class="progress-bar bg-gradient-success"
                  role="progressbar"
                  style="width: 80%"
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">230019</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="px-2 py-1 d-flex">
                <div>
                  <img
                    src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/chair-pink.jpg"
                    class="avatar avatar-md me-3"
                    alt="table image"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Bar Height Swivel Barstool</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="mb-0 text-sm text-secondary">$99.99</p>
            </td>
            <td>
              <div class="rating ms-lg-n4">
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">star_outline</i>
              </div>
            </td>
            <td class="text-sm align-middle">
              <div class="mx-auto progress">
                <div
                  class="progress-bar bg-gradient-success"
                  role="progressbar"
                  style="width: 90%"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">87120</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="px-2 py-1 d-flex">
                <div>
                  <img
                    src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/chair-steel.jpg"
                    class="avatar avatar-md me-3"
                    alt="table image"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Signature Design by Ashley</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="mb-0 text-sm text-secondary">$129.00</p>
            </td>
            <td>
              <div class="rating ms-lg-n4">
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">star_outline</i>
              </div>
            </td>
            <td class="text-sm align-middle">
              <div class="mx-auto progress">
                <div
                  class="progress-bar bg-gradient-warning"
                  role="progressbar"
                  style="width: 60%"
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">412301</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="px-2 py-1 d-flex">
                <div>
                  <img
                    src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/chair-wood.jpg"
                    class="avatar avatar-md me-3"
                    alt="table image"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">Modern Square</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="mb-0 text-sm text-secondary">$59.99</p>
            </td>
            <td>
              <div class="rating ms-lg-n4">
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">grade</i>
                <i class="material-icons text-lg">star_outline</i>
              </div>
            </td>
            <td class="text-sm align-middle">
              <div class="mx-auto progress">
                <div
                  class="progress-bar bg-gradient-warning"
                  role="progressbar"
                  style="width: 40%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </td>
            <td class="text-center align-middle">
              <span class="text-sm text-secondary">001992</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherProducts",
};
</script>
